// Copied
export enum BackgroundColor {
    Blue = 'blue',
    White = 'white',
    Green = 'green',
    Orange = 'orange',
    Yellow = 'yellow',
}

export interface User {
    id?: number;
    address: string;
    link: string;
    username: string;
    bio?: string;
    photo?: string;
    background: BackgroundColor;
    twitter?: string;
    instagram?: string;
    facebook?: string;
    email?: string;
}

export const EmptyUser: User = {
    address: '',
    link: '',
    username: 'Anonymous',
    background:BackgroundColor.White
}
