import { RowHeader } from './RowHeader/RowHeader'
import { RowItem } from './RowItem/RowItem'
import { DashboardData } from '../consts'
import { ethers } from "ethers"
import styles from './Table.module.scss'


type Props = {
    data: DashboardData,
    onOpenWithdraw?: (beneficiary: ethers.AddressLike, amount: bigint) => void,
}

export function Table(props: Props) {
    return (
        <div className={styles['wrapper']}>
            <RowHeader headers={props.data.headers} />
            {props.data.patrons.map((item) => {
                return (
                    <RowItem
                        key={item.id}
                        patron={item}
                        columnWidth={props.data.columnWidth}
                        onOpenWithdraw={props.onOpenWithdraw} />
                )
            })}
        </div>
    );
}

