import {useEffect, useState} from 'react';
import styles from "./CopyButton.module.css";
import {Button, ButtonSize, ButtonStyle} from "../Button/Button";

const COPY_BUTTON_ANIMATION_DELAY_MS = 1200;

type Props = {
    textToCopy: string;
    idleStyle?: ButtonStyle;
    copiedStyle?: ButtonStyle;
    idleCaption?: string;
    size?: ButtonSize;
    isPreview?: boolean;
}

export function CopyButton(props: Props) {
    const [isCopied, setIsCopied] = useState(false);

    const copyClaimLink = async () => {
        await navigator.clipboard.writeText(props.textToCopy);
        setIsCopied(true);
    }

    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => {
                setIsCopied(false);
            }, COPY_BUTTON_ANIMATION_DELAY_MS);
            return () => clearTimeout(timer);
        }
    }, [isCopied]);

    return(
        <div className={styles['actionButton']}>
            <Button
                size={props.size ?? 'xs'}
                type={isCopied ? (props.copiedStyle ?? 'light') : (props.idleStyle ?? 'border')}
                stretch={true}
                onClick={copyClaimLink}
                isPreview={props.isPreview}
            >
                {isCopied ? "Copied!" : (props.idleCaption ?? "Copy claim link")}
            </Button>
        </div>
    );
}