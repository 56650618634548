import React from 'react'
import { Icon } from 'components/Icon/Icon'
import styles from './MobileMenu.module.scss'
// import userpickimg from 'assets/userpickimg.png'
import { Button } from 'components/Button/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { DataType } from '../MenuBar/MenuBar'
import classnames from 'classnames'
import { formatWalletAddress } from 'layout/NavBar/helpers'
import userpickimg from 'assets/userpickimg.png'
import { useDBUser } from 'components/UserInfoContext/UserInfoContext'
import { useWeb3 } from 'components/Web3Context/Web3Context'
// import { UserInfoType } from '../UserMenu/const'
// import { Menu } from '../Menu/Menu'


type Props = {
    onClose: () => void
    menu: DataType[]
    userData?: any,
    onOpenEditPage: () => void,
}

export function MobileMenu(props: Props): JSX.Element {
    const containerRef = React.useRef(null)
    let location = useLocation()
    const navigate = useNavigate()
    const { user } = useDBUser()
    const { isConnected, connectWeb3, userAddress } = useWeb3()

    function navigateToFaq() {
        window.open('http://localhost:3000/faq', '_blank')
    }

    function handleNavigate(link) {
        navigate(link)
        props.onClose()
    }

    const handleOutsideClick = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            props.onClose()
        }
    }

    function handleOpenEditPopup() {
        props.onOpenEditPage()
        props.onClose()
    }

    React.useEffect(() => {
        window.addEventListener('mousedown', handleOutsideClick)

        return () => {
            window.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])


    return (
        <div className={styles['modal']}>
            <div className={styles['wrapper']} ref={containerRef}>
                <div className={styles['form']}>
                    <div className={styles['header']}>
                        <div className={styles['logoWrapp']}>
                            <div className={styles['svg']} onClick={props.onClose}>
                                <Icon name='cross' />
                            </div>
                            <div className={styles['logo']}>
                                <Icon name='drizzle' />
                            </div>
                        </div>
                        <div>
                            <Button
                                size='s'
                                type='transparent'
                                iconName='external'
                                iconRight={true}
                                stretch={false}
                                onClick={navigateToFaq}
                            >
                                FAQ
                            </Button>
                        </div>
                    </div>
                    <div className={styles['menu']}>
                        <div className={styles['menu__header']}>
                            <img className={styles['img']} src={userpickimg} alt='user' />
                            <div className={styles['content']}>
                                <span className={styles['name']}>{user.username}</span>
                            </div>
                        </div>
                        <div className={styles['menuList']}>
                            {props.menu.map((item) => {
                                const itemClassName = classnames({
                                    [styles['menuList__item']]: true,
                                    [styles['menuList__item_active']]: location.pathname === item.link,
                                })

                                return (
                                    <div className={itemClassName}
                                        onClick={() => handleNavigate(item.link)}
                                        key={item.id}
                                    >
                                        {item.title}
                                    </div>
                                )
                            })}
                        </div>
                        {isConnected && (
                            <div className={styles['menu__account']} onClick={() => { }}>
                                <div className={styles['menu__account__container']}>
                                    <Icon name='wallet' />
                                    <span>{formatWalletAddress(userAddress)}</span>
                                </div>
                            </div>
                        )}
                        {!isConnected && (
                            <div className={styles['menu__item']} onClick={connectWeb3}>
                                <Icon name='add' />
                                <span>Connect wallet</span>
                            </div>
                        )}
                        <div className={styles['menu__item']} onClick={handleOpenEditPopup}>
                            <Icon name='edit' />
                            <span>Edit profile</span>
                        </div>
                    </div>
                    {/* <Menu onOpenEditPage={handleOpenEditPopup} onClose={props.onClose} extraContent={
                        <div className={styles['menuList']}>
                            {props.menu.map((item) => {
                                const itemClassName = classnames({
                                    [styles['menuList__item']]: true,
                                    [styles['menuList__item_active']]: location.pathname === item.link,
                                })

                                return (
                                    <div className={itemClassName}
                                        onClick={() => handleNavigate(item.link)}
                                        key={item.id}
                                    >
                                        {item.title}
                                    </div>
                                )
                            })}
                        </div>
                    } /> */}
                </div>
            </div>
        </div>
    )
}

