import { AnalyticsType } from '../consts'
import styles from './Analytics.module.scss'
import { Block } from './Block/Block'


type Props = {
    analytics: AnalyticsType[]
}

export function Analytics(props: Props) {
    return (
        <div className={styles['wrapper']}>
            {props.analytics.map((item) => {
                return (
                    <Block
                        key={item.id}
                        number={item.number}
                        description={item.description}
                        helpText={item.helpText}
                        isCurrency={item.isCurrency} />
                )
            })}
        </div>
    );
}

