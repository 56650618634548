import { Icon } from 'components/Icon/Icon'
import styles from './AlreadyClaimed.module.scss'


export function AlreadyClaimed() {
    return (
        <div className={styles['form']}>
            <div>
                <Icon name='pig' />
            </div>
            <h1 className={styles['title']}>
                This drizzle has already been claimed
            </h1>
            <div>
                <article className={styles['item']}>
                    <span>
                       The link has expired. If you're certain a drizzle was sent to you and you didn't get it,
                        please contact the sender.
                    </span>
                </article>
            </div>
        </div>
    )
}

