import classnames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './MenuBar.module.scss'


export type DataType = {
    id: number,
    title: string,
    value: string,
    link: string,
}

type Props = {
    data: DataType[],
    isNegative?: boolean;
}

export function MenuBar(props: Props) {
    let location = useLocation();
    const navigate = useNavigate()

    function handleNavigate(link) {
        navigate(link)
    }

    return (
        <div className={styles['menubar']}>
            {props.data.map((item) => {
                const itemClassName = classnames({
                    [styles['item']]: true,
                    [styles['item_active']]: (!props.isNegative && location.pathname === item.link),
                    [styles['item_negative']]: props.isNegative,
                    [styles['item_negative_active']]: (props.isNegative && location.pathname === item.link),
                })

                return (
                    <div className={itemClassName}
                        onClick={() => handleNavigate(item.link)}
                        key={item.id}
                    >
                        {item.title}
                    </div>
                )
            })}
        </div >
    );
}

