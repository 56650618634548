import React from 'react'
import styles from './PopupWithdraw.module.scss'
import { Modal } from 'components/Modal/Modal'
import { Icon } from 'components/Icon/Icon'
import { Button } from 'components/Button/Button'
import classnames from 'classnames'
import { PATRON_STATUS } from '../consts'
import {useWeb3} from "../../Web3Context/Web3Context";
import {toast} from "react-toastify";
import {ethers} from "ethers";
import {EthAddress} from "../../EthAddress/EthAddress";

type Props = {
    balance: bigint,
    beneficiary: ethers.AddressLike,
    onClose: () => void,
    onWithdraw: () => void,
}

const REDIRECT_TIMER_S = 3;
const DISPLAY_INTEREST = false;
const DISPLAY_STATUS = false;

export function PopupWithdraw(props: Props) {
    const [redirectTimer, setRedirectTimer] = React.useState(REDIRECT_TIMER_S);
    const [countdownStarted, setCountdownStarted] = React.useState(false);

    const { usdcEthToHuman, withdrawDrizzle } = useWeb3();

    const startCountdown = () => {
        const countdown = (count) => {
            if (count === 0) {
                toast.dismiss();
                props.onWithdraw();
            } else {
                setRedirectTimer(count - 1);
                setTimeout(() => countdown(count - 1), 1000);
            }
        }

        setCountdownStarted(true);
        setTimeout(() => countdown(REDIRECT_TIMER_S), 1000)
    }

    function handleWithdraw() {
        (async () => {
            await withdrawDrizzle(props.beneficiary, props.balance);
            startCountdown();
        })().catch(console.error);
    }

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
    }

    // test data for status
    const STATUS_MOCK_DATA = PATRON_STATUS.UNCLAIMED

    const statusClassNames = classnames({
        [styles['status']]: true,
        [styles['status_green']]: STATUS_MOCK_DATA === PATRON_STATUS.WITHDREW,
        [styles['status_blue']]: STATUS_MOCK_DATA === PATRON_STATUS.CLAIMED,
        [styles['status_orange']]: STATUS_MOCK_DATA === PATRON_STATUS.UNCLAIMED,
    })

    return (
        <Modal onClose={props.onClose}>
            <form className={styles['wrapper']} onSubmit={onSubmit}>
                <div className={styles['saving']}>
                    <Icon name='withdraw' />
                </div>
                <div>
                    <div className={styles['content']}>
                        <div className={styles['row']}>
                            <span>Recepient</span>
                            <span><EthAddress address={props.beneficiary} /></span>
                        </div>
                        <div className={styles['row']}>
                            <span>Deposit</span>
                            <span>${usdcEthToHuman(props.balance)}</span>
                        </div>
                        {DISPLAY_INTEREST &&
                        <div className={styles['row']}>
                            <span>Available interest</span>
                            <span>${usdcEthToHuman(props.balance)}</span>
                        </div>
                        }
                        {DISPLAY_STATUS &&
                        <div className={styles['row']}>
                            <span>Status</span>
                            <span className={statusClassNames}>UNCLAIMED</span>
                        </div>
                        }
                        <span className={styles['description']}>
                            Do you really want to withdraw your deposit?
                            <br />
                            The beneficiary will no longer receive your support. They still get the interest accumulated up to now</span>
                    </div>
                </div>
                {!countdownStarted &&
                <div className={styles['button']}>
                    <Button
                        size='l'
                        type='border'
                        stretch={false}
                        onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button size='l' type='dark' onClick={handleWithdraw}>
                        Withdraw ${usdcEthToHuman(props.balance)}
                    </Button>
                </div>
                }
                {countdownStarted &&
                <div className={styles['button']}>
                    <Button size='l' type='dark' onClick={props.onClose}>
                        All done! Closing in {redirectTimer}...
                    </Button>
                </div>
                }
            </form>
        </Modal>
    )
}

