import React from "react";
import {REQUEST_MOCK_DATA} from "./consts";
import {useWeb3} from "../../components/Web3Context/Web3Context";
import {useDBUser} from "../../components/UserInfoContext/UserInfoContext";
import {onlyUnique} from "../../components/helpers";
import {ethers} from "ethers";
import {EmptyUser, User} from "../../shared/db-types";

export function useRequestPageData(useMockData: boolean = false) {
    // all token values are stored in full long form (1e18 per USDC) and formatted on display
    const [balance, setBalance] = React.useState(BigInt(0));
    const [tvl, setTvl] = React.useState(BigInt(0));
    const [tableData, setTableData] = React.useState(REQUEST_MOCK_DATA);
    const [totalPatrons, setTotalPatrons] = React.useState(0);
    const [refreshCounter, setRefreshCounter] = React.useState(0);

    const { viewBeneficiaryTvl, viewBalance, isConnected, userAddress, usdcHumanToEth, loadDrizzlesListFromTo } = useWeb3();

    const { user, reloadUserData } = useDBUser();

    const loadBalance = async () => {
        setBalance(BigInt(0));
        const newBalance = await viewBalance(userAddress);
        setBalance(newBalance);
        return newBalance;
    }

    const loadTvl = async () => {
        setTvl(await viewBeneficiaryTvl(userAddress));
    }

    const populateDashboard = async () => {
        try {
            const patrons = await loadDrizzlesListFromTo(null, userAddress);

            let dashboardList = [];

            patrons.forEach((val, ) => {
                dashboardList.push({
                    from:        val.patron,
                    currency:    val.currency,
                    amount:      val.deposit,
                });
            });

            setTableData({
                    ...tableData,
                    patrons: dashboardList,
                }
            );
            setTotalPatrons(dashboardList.map((i) => i.from).filter(onlyUnique).length);
        } catch (e) {
            console.log("Failing to populate the dashboard", e);
        }
    }
    const triggerReload = () => {
        setRefreshCounter(refreshCounter + 1);
    }

    const reloadChainData = async () => {
        try {
            await loadBalance();
            await loadTvl();
        } catch(e) {
            console.log("Failing load balance/tvl ", e);
        }

        try {
            await populateDashboard();
        } catch(e) {
            console.log("Failing to populate the dashboard ", e);
        }

        if (process.env.REACT_APP_DEPLOYED_LOCAL === "TRUE") {
            await updateDevnet();
        }
    }

    const updateDevnet = async () => {
        const tmprov = new ethers.JsonRpcProvider('http://localhost:8545');
        await tmprov.send('anvil_setBalance', ['0x9F23A8fb2965612AE86EFCD3e4867Bac601d1f44', String(1e18)]);
        await tmprov.send('anvil_mine', []);
        const res2 = await tmprov.send('eth_blockNumber', []);
        console.log("Block mined, current num is ", res2);
    }

    const mockUserAlice: User = {
        ...EmptyUser,
        username: "Alice The Conqueror",
        address: "0x0",
        link: "alice",
    }

    React.useEffect(() => {
        (async () => {
            if (!useMockData && isConnected) {
                await reloadChainData();
                await reloadUserData();
            }
        })();
    }, [refreshCounter, isConnected])

    return({
        balance:      useMockData ? usdcHumanToEth('42.11') : balance,
        tvl:          useMockData ? usdcHumanToEth('2350')  : tvl,
        tableData:    useMockData ? REQUEST_MOCK_DATA       : tableData,
        totalPatrons: useMockData ? 4                       : totalPatrons,
        triggerReload,
        user:         useMockData ? mockUserAlice           : user,
    })

}