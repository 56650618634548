import { ethers } from 'ethers';

class SecureLocalStorage {
    private static readonly STORAGE_PREFIX = 'secure_claim_link_';
    private static readonly ENCRYPTION_KEY = 'drizzleFinance2024';
    private static crypto = window.crypto.subtle;

    private static async getEncryptionKey(): Promise<CryptoKey> {
        const encoder = new TextEncoder();
        const keyMaterial = await window.crypto.subtle.importKey(
            'raw',
            encoder.encode(this.ENCRYPTION_KEY),
            { name: 'PBKDF2' },
            false,
            ['deriveKey']
        );

        return window.crypto.subtle.deriveKey(
            {
                name: 'PBKDF2',
                salt: encoder.encode('salt'),
                iterations: 100000,
                hash: 'SHA-256'
            },
            keyMaterial,
            { name: 'AES-GCM', length: 256 },
            false,
            ['encrypt', 'decrypt']
        );
    }

    private static async encrypt(data: string): Promise<string> {
        const key = await this.getEncryptionKey();
        const encoder = new TextEncoder();
        const iv = crypto.getRandomValues(new Uint8Array(12));
        const encryptedData = await this.crypto.encrypt(
            { name: 'AES-GCM', iv },
            key,
            encoder.encode(data)
        );
        const encryptedArray = new Uint8Array(iv.length + encryptedData.byteLength);
        encryptedArray.set(iv);
        encryptedArray.set(new Uint8Array(encryptedData), iv.length);
        return btoa(String.fromCharCode.apply(null, Array.from(encryptedArray)));
    }

    private static async decrypt(encryptedData: string): Promise<string> {
        const key = await this.getEncryptionKey();
        const decoder = new TextDecoder();
        const encryptedArray = new Uint8Array(atob(encryptedData).split('').map(char => char.charCodeAt(0)));
        const iv = encryptedArray.slice(0, 12);
        const data = encryptedArray.slice(12);
        const decryptedData = await this.crypto.decrypt(
            { name: 'AES-GCM', iv },
            key,
            data
        );
        return decoder.decode(decryptedData);
    }

    static async storeClaimLink(address: ethers.AddressLike, claimLink: string): Promise<void> {
        const addressStr = ethers.getAddress(String(address));
        const encryptedLink = await this.encrypt(claimLink);
        localStorage.setItem(this.STORAGE_PREFIX + addressStr, encryptedLink);
    }

    static async getClaimLink(address: ethers.AddressLike): Promise<string | null> {
        const addressStr = ethers.getAddress(String(address));
        const encryptedLink = localStorage.getItem(this.STORAGE_PREFIX + addressStr);
        if (!encryptedLink) return null;
        return this.decrypt(encryptedLink);
    }

    static async getAllClaimLinks(): Promise<Array<{ address: string; claimLink: string }>> {
        const links: Array<{ address: string; claimLink: string }> = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key && key.startsWith(this.STORAGE_PREFIX)) {
                const address = key.slice(this.STORAGE_PREFIX.length);
                const claimLink = await this.getClaimLink(address);
                if (claimLink) {
                    links.push({ address, claimLink });
                }
            }
        }
        return links;
    }

    static removeClaimLink(address: ethers.AddressLike): void {
        const addressStr = ethers.getAddress(String(address));
        localStorage.removeItem(this.STORAGE_PREFIX + addressStr);
    }

    static clearAllClaimLinks(): void {
        for (let i = localStorage.length - 1; i >= 0; i--) {
            const key = localStorage.key(i);
            if (key && key.startsWith(this.STORAGE_PREFIX)) {
                localStorage.removeItem(key);
            }
        }
    }
}

export default SecureLocalStorage;