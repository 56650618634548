import classnames from 'classnames'
import styles from './Attention.module.scss'
import { Icon } from 'components/Icon/Icon'

type Props = {
    children: string;
    color: 'red' | 'blue';
};

export function Attention(props: Props): JSX.Element {
    const buttonClassNames = classnames({
        [styles['attention']]: true,
        [styles[`attention_${props.color}`]]: true,
    })

    return (
        <div className={buttonClassNames} >
            <div className={styles['icon']}>
                <Icon name='attention' />
            </div>
            <div className={styles['text']}>{props.children}</div>
        </div>
    )
}