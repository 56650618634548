import { Icon } from 'components/Icon/Icon'
import { toast, Id } from 'react-toastify'
import styles from './Notification.module.scss'

const CustomToastContent = ({ title, description }) => (
    <div className={styles['wrapper']}>
        <Icon name='coin_toast' />
        <div className={styles['content']}>
            <span className={styles['title']}>{title}</span>
            <span className={styles['description']}>{description}</span>
        </div>
    </div>
)

export function notify(title, description) {
    toast(<CustomToastContent title={title} description={description} />, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            backgroundColor: '#A6DAA8',
            color: '#3c763d',
            borderRadius: '16px',
            padding: '24px',
            width: "auto"
        },
        progressStyle: {
            background: 'green',
        },
    })
}
export function notifyInProgress(title, description): Id {
    return toast(<CustomToastContent title={title} description={description} />, {
        position: 'bottom-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            backgroundColor: '#fdf4a6',
            color: '#3c763d',
            borderRadius: '16px',
            padding: '24px',
            width: "auto"
        },
        progressStyle: {
            background: 'green',
        },
    })
}

export function notifyProblem(title, description) {
    toast(<CustomToastContent title={title} description={description} />, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            backgroundColor: '#ffb2b2',
            color: '#3c763d',
            borderRadius: '16px',
            padding: '24px',
            width: "auto"
        },
        progressStyle: {
            background: 'green',
        },
    })
}
