import React from 'react';
import {Button} from "../Button/Button";
import {useWeb3, ConnectMetamaskResponse} from "../Web3Context/Web3Context";

type Props = {
    captionType?: 'continue' | 'connect',
    onRejected?: () => void,
    onSuccess?: () => void,
}

export function ButtonConnectMetamask(props: Props) {
    const { connectWeb3 } = useWeb3();

    const connect = async () => {
        const response: ConnectMetamaskResponse = await connectWeb3();
        if (response === "rejected") {
            if (props.onRejected) props.onRejected();
        } else if (response === "success") {
            if (props.onSuccess) props.onSuccess();
        }
    }

    return(
        <Button
            size='m'
            type='dark'
            iconName='metamask'
            onClick={connect}
        >
            {props.captionType === 'connect'  && "Connect Metamask"}
            {props.captionType === 'continue' && "Continue with Metamask"}
            {!props.captionType               && "Continue with Metamask"}
        </Button>
    )
}