import React from 'react'
import styles from './PopupBalance.module.scss'
import { Modal } from 'components/Modal/Modal'
import { Icon } from 'components/Icon/Icon'
import { Button } from 'components/Button/Button'
import {formatNumberWithCommas} from "../../../../../components/helpers";
import {useWeb3} from "../../../../../components/Web3Context/Web3Context";
import {toast} from "react-toastify";

type Props = {
    onClose: () => void;
    balance: bigint;
}

const REDIRECT_TIMER_S = 3;

export function PopupBalance(props: Props) {
    const [redirectTimer, setRedirectTimer] = React.useState(REDIRECT_TIMER_S);
    const [countdownStarted, setCountdownStarted] = React.useState(false);

    const { isConnected, connectWeb3, withdrawInterest } = useWeb3();

    const startCountdown = () => {
        const countdown = (count) => {
            if (count === 0) {
                toast.dismiss();
                props.onClose();
            } else {
                setRedirectTimer(count - 1);
                setTimeout(() => countdown(count - 1), 1000);
            }
        }

        setCountdownStarted(true);
        setTimeout(() => countdown(REDIRECT_TIMER_S), 1000)
    }

    function handleWithdraw() {
        (async () => {
            await withdrawInterest();
            startCountdown();
        })().catch(console.error);
    }

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
    }

    React.useEffect(() => {
        (async () => {
            if (!isConnected) {
                await connectWeb3();
            }
        })();
    }, [isConnected])

    return (
        <Modal onClose={props.onClose}>
            <form className={styles['wrapper']} onSubmit={onSubmit}>
                <div className={styles['saving']}>
                    <Icon name='tree' />
                </div>
                {countdownStarted ? (
                    <>
                        <div>
                            <div className={styles['content']}>
                                <span className={styles['title']}>Balance widhtdawn!</span>
                                <span className={styles['description']}>This window will close in {redirectTimer}...</span>
                            </div>
                        </div>
                        <div className={styles['button']}>
                            <Button size='l' type='dark' stretch={true} onClick={props.onClose}>
                                Close now! Quick!
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <div className={styles['content']}>
                                <span className={styles['title']}>Withdraw total balance:</span>
                                <span
                                    className={styles['amount']}> {formatNumberWithCommas('$', String(props.balance))}</span>
                                <span className={styles['description']}>Your Patrons' deposits will continue generating new earnings</span>
                            </div>
                        </div>
                        <div className={styles['button']}>
                            <Button size='l' type='border' stretch={false} onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button size='l' type='dark' onClick={handleWithdraw}>
                                Withdraw
                            </Button>
                        </div>
                    </>
                )
                }
            </form>
        </Modal>
    )
}

