import React from 'react'
import styles from './Chips.module.scss'
import { Icon } from 'components/Icon/Icon'
import { PopupEditChips } from './PopupEditChips/PopupEditChips'


type Props = {
    name: string,
    title: string,
    value?: string | null,
    onClick?: () => void,
    iconName?: string,
    onSave: (newValue: string) => void,
}

export function Chips(props: Props): JSX.Element {
    const containerRef = React.useRef(null)
    const [openMenu, setOpenMenu] = React.useState(false)
    const [openPopupEdit, setOpenPopupEdit] = React.useState(false)

    const iconActionName = props.value ? 'edit' : 'add'

    function handleClick() {
        if (props.value) {
            setOpenMenu(!openMenu)
            return
        }

        setOpenPopupEdit(true)
    }

    function closePopupEdit() {
        setOpenPopupEdit(false)
    }

    function handleEdit() {
        setOpenMenu(false);
        setOpenPopupEdit(true)
    }

    function handleDelete() {
        setOpenMenu(false);
        props.onSave(null);
    }

    const handleOutsideClick = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setOpenMenu(false);
        }
    }

    React.useEffect(() => {
        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [])

    return (
        <>
            <div className={styles['wrapper']} ref={containerRef}>
                <button
                    className={styles['chips']}
                    onClick={handleClick}
                    name={props.name}
                >
                    {props.iconName && <Icon name={props.iconName} />}
                    <span>
                        {props.value ? props.value : props.title}
                    </span>
                    <Icon name={iconActionName} />
                </button>
                {openMenu && (
                    <div className={styles['menu']}>
                        <div
                            className={styles['menu__item']}
                            onClick={handleEdit}
                        >
                            <Icon name='edit' />
                            <span>Edit Link</span>
                        </div>
                        <div
                            className={styles['menu__item']}
                            onClick={handleDelete}
                        >
                            <Icon name='delete' />
                            <span>Delete</span>
                        </div>
                    </div>
                )}
            </div>
            {openPopupEdit && <PopupEditChips
                title={props.title} name={props.name} value={props.value ? props.value : ''}
                onClose={closePopupEdit} onSave={props.onSave} />}
        </>
    )
}