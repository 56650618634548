import React from 'react'
import styles from './MultiRowInput.module.scss'
import classnames from 'classnames'

type Props = {
    label?: string,
    defaultValue?: string,
    placeholder?: string,
    onChange?: (target: EventTarget & HTMLTextAreaElement) => void,
    name: string,
    maxWidth?: string,
    error?: string,
    value?: string,
}

export function MultiRowInput(props: Props): JSX.Element {
    const [inputValue, setInputValue] = React.useState('')
    const maxCharacters = 180


    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        if (props.onChange)
            props.onChange(event.currentTarget)
        setInputValue(event.currentTarget.value)
    }

    const counterClassNames = classnames({
        [styles['counter']]: true,
        [styles['counter_error']]: inputValue.length > maxCharacters,
    })

    return (
        <div className={styles['wrapper']} style={{ maxWidth: `${props.maxWidth}px` }}>
            <label htmlFor={props.name} className={styles['label']}>
                <span>{props.label}</span>
                <div className={counterClassNames}>
                    {props.value ? props.value.length : inputValue.length}/{maxCharacters}
                </div>
            </label>
            <textarea
                id={props.name}
                rows={2}
                name={props.name}
                className={styles['input']}
                placeholder={props.placeholder}
                onChange={handleChange}
                value={props.value}
            />
            {props.error && <div className={styles['error']}>{props.error}</div>}
        </div>
    )
}
