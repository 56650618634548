import React, {createContext, ReactNode, useContext} from 'react';
import {readUserByAddress, updateUser} from "../userAPI";
import {EmptyUser, User} from "../../shared/db-types";
import {useWeb3} from "../Web3Context/Web3Context";
import {ethers} from "ethers";

interface UserInfoProviderProps {
    children: ReactNode;
}

type StoredUsers = { [address: string]: User | null }

const UserInfoContext = createContext<any>(null);

export const UserInfoProvider: React.FC<UserInfoProviderProps> = (props: UserInfoProviderProps) => {
    const [user, setUser] = React.useState(EmptyUser);
    const [isUser, setIsUser] = React.useState(false);
    const storedUsersRef = React.useRef<StoredUsers>({});

    const { isConnected, userAddress } = useWeb3();

    const setEmptyUser = () => {
        const u = EmptyUser;
        setUser(u);
        setIsUser(false)
        return u;
    }

    const reloadUserData = async () => {
        if (isConnected) {
            try {
                const loadedProfile = await readUserByAddress(userAddress);
                if (loadedProfile === EmptyUser) {
                    const u = setEmptyUser();
                    setUser({...u, address: userAddress});
                } else {
                    setUser(loadedProfile);
                    setIsUser(true);
                }
            } catch (e) {
                console.log("Profile load: error", e);
            }
        }
    }

    const fetchUser = async (address: ethers.AddressLike) => {
        const addressString = String(address);
        if (addressString in storedUsersRef.current) {
            return storedUsersRef.current[addressString];
        } else {
            const loadedProfile = await readUserByAddress(addressString);
            const profile = loadedProfile === EmptyUser ? null : loadedProfile;
            storedUsersRef.current[addressString] = loadedProfile;
            return profile;
        }
    }

    const lookupUsernameByAddress = async (address: ethers.AddressLike) => {
        const maybeUser = await fetchUser(address);
        return maybeUser?.username ?? null;
    }

    const lookupLinkByAddress = async (address: ethers.AddressLike) => {
        const maybeUser = await fetchUser(address);
        return maybeUser?.link ?? null;
    }

    const saveUserToDB = async (changedUser: User) => {
        try {
            await updateUser(changedUser);
            setUser(changedUser);
        } catch(e) {
            console.error("Failing to save user to db", e);
        }
    }

    React.useEffect(() => {
        (async () => {
            await reloadUserData();
        })();
    }, [isConnected])

    return(
        <UserInfoContext.Provider value={{
            user, isUser, lookupLinkByAddress, lookupUsernameByAddress,
            reloadUserData, saveUserToDB
        }}>
            {props.children}
        </UserInfoContext.Provider>
    )
}

export const useDBUser = () => useContext(UserInfoContext);