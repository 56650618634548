import styles from './EditNavBar.module.scss'
import { Icon } from 'components/Icon/Icon'
import { Button } from 'components/Button/Button'
import classnames from 'classnames'
import { useIsMobile } from 'hooks/useIsMobile'


type Props = {
    onClose?: () => void,
    isDataChanged?: boolean,
    onSubmit?: () => void,
    onDiscard?: () => void,
    userLink?: string,
}

export function EditNavBar(props: Props) {
    const { isMobile } = useIsMobile()

    function navigateTo() {
        window.open(process.env.REACT_APP_BASE_URL + '/u/' + props.userLink, '_blank')
    }

    function handleSubmit() {
        // handle submit
        props.onSubmit()
    }

    function handleDiscard() {
        // handle discard
        props.onDiscard()
    }

    const navbarClassName = classnames({
        [styles['container']]: true,
        [styles['container_active']]: props.isDataChanged
    });

    return (
        <div className={navbarClassName}>
            <div className={styles['content']}>
                <div className={styles['cross']} onClick={props.onClose}>
                    <Icon name='cross' />
                </div>
                <span className={styles['title']}>
                    My Drizzle page
                </span>
                {props.userLink && (!isMobile || (isMobile && !props.isDataChanged)) &&
                    <Button
                        size='s'
                        type='dark'
                        iconName='external'
                        iconRight={true}
                        stretch={false}
                        onClick={navigateTo}
                    >
                        View page
                    </Button>
                }
            </div>
            <div className={styles['content']}>
                {props.isDataChanged && (
                    <>
                        <span className={styles['info']}>
                            You have unpublished changes
                        </span>
                        <Button
                            size='xs'
                            type='border'
                            stretch={false}
                            onClick={handleDiscard}
                        >
                            Discard
                        </Button>
                        <Button
                            size='xs'
                            type='primary'
                            stretch={false}
                            onClick={handleSubmit}
                        >
                            Save & Publish
                        </Button>
                    </>
                )}
            </div >
        </div >
    )
}

