import React from 'react';
import styles from './SignupContent.module.scss'
import { SignupStep1 } from './SignupStep1/SignupStep1'
import { SignupStep2 } from './SignupStep2/SignupStep2'
import { SignupStep3Creator } from './SignupStep3Creator/SignupStep3Creator'
import { SignupStepPublicEdit } from './SignupStepPublicEdit/SignupStepPublicEdit'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'components/Icon/Icon'
import {SignupStep3Patron} from "./SignupStep3Patron/SignupStep3Patron"
import {useDBUser} from "../../../../components/UserInfoContext/UserInfoContext"


type Props = {
    hideNavBar: () => void,
    returnNavBar: () => void,
    showClaimCard: () => void,
    showDefault: () => void,
    showPhone: () => void,
    onChangeStep?: (step: string) => void
}

export function SignupContent(props: Props) {
    const [stage, setStage] = React.useState('1');
    const navigate = useNavigate()

    const { reloadUserData } = useDBUser();

    async function gotoCreator() {
        await reloadUserData();
        navigate('/request');
    }

    async function gotoPatron() {
        await reloadUserData();
        navigate('/support');
    }

    const setStage3Support = () => {
        props.showClaimCard();
        props.onChangeStep('3p')
        setStage('3p');
    }

    const setStage3Receive = () => {
        props.showPhone();
        props.onChangeStep('3c')
        setStage('3c');
    }

    function step2(){
        props.onChangeStep('2')
        setStage('2')
    }

    function switchSignup() {
        switch (stage) {
            case '1':
                return <SignupStep1 onSubmit={step2} />
            case '2':
                props.hideNavBar();
                return <SignupStep2
                            onSupport={setStage3Support}
                            onReceive={setStage3Receive}
                        />
            case '3c':
                return <SignupStep3Creator onCreatePublicPage={() => setStage('editPage')} />
            case '3p':
                return <SignupStep3Patron onSubmit={gotoPatron} />
            case 'editPage':
                return <SignupStepPublicEdit onSubmit={gotoCreator} />
        }
    }

    function stepBack() {
        switch (stage) {
            case '2':
                props.returnNavBar();
                props.showDefault();
                props.onChangeStep('1')
                setStage('1');
                break;
            case '3c':
                props.showDefault();
                props.onChangeStep('2')
                setStage('2');
                break;
            case '3p':
                props.showDefault();
                props.onChangeStep('2')
                setStage('2');
                break;
            case 'editPage':
                props.onChangeStep('3c')
                setStage('3c');
                break;
        }
    }


    return (
        <div>
            {stage !== '1' &&
                <div className={styles['back']} onClick={stepBack}>
                    <Icon name='back'/>
                    back
                </div>
            }
            {switchSignup()}
        </div>
    );
}

