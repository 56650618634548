import { useState, useEffect } from 'react'

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        // Функция для проверки ширины экрана
        const handleResize = () => {
            setScreenWidth(window.innerWidth) // Обновляем ширину экрана
            setIsMobile(window.innerWidth <= 840)
        }

        // Первоначальная проверка
        handleResize()

        // Добавляем обработчик события изменения размера окна
        window.addEventListener('resize', handleResize)

        // Удаляем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return { isMobile, screenWidth }
}

