import React from "react";
import {Route, Routes, Navigate, useParams} from "react-router-dom"
import { Layout } from "layout/Layout"
import { Request } from "pages/Request/Request"
import { Support } from 'pages/Support/Support'
import { Auth } from "pages/Auth/Auth"
import { Deposit } from "pages/Deposit/Deposit"
import { Claim } from "pages/Claim/Claim"
import { PopupDepositPlaced } from "components/PopupDepositPlaced/PopupDepositPlaced"
import {FullDashboard} from "../pages/FullDashboard/FullDashboard";
import {PublicProfile} from "../pages/PublicProfile/PublicProfile";
import {readUserByLinkOrNull} from "../components/userAPI";
import {UserNotFound} from "../pages/UserNotFound/UserNotFound";

const ClaimWrapper = () => {
    const params = useParams();
    const encodedEncryptedPkey = params.encodedPkey;

    const base64Pkey = decodeURIComponent(encodedEncryptedPkey || '');
    const encryptedPkey = atob(base64Pkey);

    const senderAddr = params.senderAddr;
    const decodedAddr = atob(senderAddr);

    const amount = params.amount;
    const decodedAmount = amount ? Number(atob(amount)) : undefined;

    const color = params.color;
    const decodedColor = atob(color);

    const senderName = params.senderName;
    const decodedSenderName = senderName ? atob(decodeURIComponent(senderName)) : undefined;

    return <Claim
        encryptedPkey={encryptedPkey}
        senderAddr={decodedAddr}
        senderName={decodedSenderName}
        amount={decodedAmount}
        color={decodedColor}
    />;
};

const ProfileRender = () => {
    const { profileLink } = useParams();
    const [found, setFound] = React.useState(null);
    const [render, setRender] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            const maybeUser = await readUserByLinkOrNull(profileLink);
            if (maybeUser) {
                setRender(<PublicProfile user={maybeUser} />);
                setFound(true);
            } else {
                setRender(<UserNotFound />);
                setFound(false);
            }
        })();
    }, [profileLink]);

    if (found === null) {
        return <div>Loading...</div>
    }

    return render;
}

export function RouterProvider() {
    return (
        <Routes>
            <Route path="/auth" element={<Layout hideNavBar={true} />} >
                <Route path="login"  element={<Auth authType='login' />} />
                <Route path="signup" element={<Auth authType='signup'/>} />
            </Route>
            <Route path="/" element={<Layout showPreviews={false} />} >
                <Route index element={<Navigate to="/preview/request" replace />} />
                <Route path="popup-deposit" element={<PopupDepositPlaced onClose={() => { }} />} />
                <Route path="request" element={<Request />} />
                <Route path="support" element={<Support />} />
                <Route path="deposit" element={<Deposit />} />
                <Route path="dashboard" element={<FullDashboard />} />
                <Route path="claim/:encodedPkey/:senderAddr/:senderName?/:amount?/:color?" element={<ClaimWrapper />} />
                <Route path="u/:profileLink" element={<ProfileRender />} />
            </Route>
            <Route path="/preview" element={<Layout showPreviews={true} />} >
                <Route path="request" element={<Request isPreview={true} />} />
                <Route path="support" element={<Support isPreview={true} />} />
                <Route path="claim" element={<Claim isPreview={true} />} />
            </Route>
            <Route path="*" element={<Navigate to={"/preview/request"} />} />
        </Routes>
    );
}