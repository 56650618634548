import React from 'react'
import styles from './PopupDepositPlaced.module.scss'
import { Modal } from 'components/Modal/Modal'
import { Icon } from 'components/Icon/Icon'
import { Button } from 'components/Button/Button'
import { CopyLink } from 'components/CopyLink/CopyLink'

type Props = {
    onClose: () => void;
    claimLink?: string;
    username?: string;
}

const REDIRECT_TIMER_S = 3;

export function PopupDepositPlaced(props: Props) {
    const [redirectTimer, setRedirectTimer] = React.useState(REDIRECT_TIMER_S);

    const startCountdown = () => {
        const countdown = (count) => {
            if (count === 0) {
                props.onClose();
            } else {
                setRedirectTimer(count - 1);
                setTimeout(() => countdown(count - 1), 1000);
            }
        }

        setTimeout(() => countdown(REDIRECT_TIMER_S), 1000)
    }

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
    }

    React.useEffect(() => {
        if (!props.claimLink) startCountdown();
    }, [props.claimLink]);

    return (
        <Modal onClose={props.onClose}>
            <form className={styles['wrapper']} onSubmit={onSubmit}>
                <div className={styles['saving']}>
                    <Icon name='patrons' />
                </div>
                <div>
                    {props.claimLink &&
                        <div className={styles['content']}>
                            <span className={styles['title']}>Deposit placed, copy the link</span>
                            <span className={styles['description']}>Here is your deposit link. Send it to recepient.</span>
                            <CopyLink link={props.claimLink}/>
                        </div>
                    }
                    {!props.claimLink &&
                    <div className={styles['content']}>
                        <span className={styles['title']}>Your deposit is made!</span>
                        <span className={styles['description']}>{props.username === "Anonymous" ? "Your beneficiary" : props.username} already started
                            receiving interest!
                        </span>
                        <span className={styles['description']}>Redirecting you in {redirectTimer}...</span>
                    </div>
                    }
                </div>
                <div className={styles['button']}>
                    <Button size='l' type='dark' onClick={props.onClose}>
                        Complete
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

