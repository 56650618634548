// src/api/userApi.ts
import axios from 'axios';
import {EmptyUser, User} from "shared/db-types";

export const readUserByAddress = async (address: string): Promise<User> => {
    const addressLowercase = address.toLowerCase();
    // console.log("Read user", addressLowercase);
    try {
        const checkResponse = await axios.get(`/api/user/checkAddr/${addressLowercase}`);
        // console.log("First response:", checkResponse);
        if (checkResponse && checkResponse.data) {
            const response = await axios.get(`/api/user/addr/${addressLowercase}`);
            // console.log("Second response", response);
            return response.data;
        } else {
            return EmptyUser;
        }
    } catch {
        console.log("Read user doesn't work at all for some reason");
    }
}

export const readUserByLinkOrNull = async (link: string): Promise<User | null> => {
    try {
        const checkResponse = await axios.get(`/api/user/checkLink/${link}`);
        if (checkResponse && checkResponse.data) {
            const response = await axios.get(`/api/user/page/${link}`);
            return response.data;
        } else {
            return null;
        }
    } catch {
        console.log("Read user doesn't work at all for some reason");
    }
}

export const updateUserName = async (address: string, name: string): Promise<User> => {
    const response = await axios.post('/api/user', { address, name });
    return response.data;
};

export const updateUser = async (user: User): Promise<number> => {
    const lowerAddress = { ...user, address: user.address.toLowerCase() }
    // console.log("Bumping user to db", lowerAddress);
    const response = await axios.post('/api/user', lowerAddress);
    // console.log("User bumped", response);
    return response.data;
}