import React from 'react';
import styles from "../PopupSendDeposit/PopupSendDeposit.module.scss";
import {Attention} from "../Attention/Attention";
import {Button} from "../Button/Button";
import {useWeb3} from "../Web3Context/Web3Context";
import {ButtonConnectMetamask} from "../ButtonConnectMetamask/ButtonConnectMetamask";

type Props = {
    recipientAddress: string,
    depositHumanUsd?: number,
    onDeposit?: () => void,
    previewMode?: boolean,
}

export function DepositButtons(props: Props) {
    const [allowance, setAllowance] = React.useState(null);
    const { isConnected, usdcHumanToEth } = useWeb3();
    const { approveUsdcToDrizzle, depositDrizzle, loadUsdcAllowanceToDrizzle } = useWeb3();
    const [metamaskRejected, setMetamaskRejected] = React.useState(false);

    const depositInEthForm: BigInt = usdcHumanToEth(props.depositHumanUsd || 0);

    async function loadAllowance() {
        try {
            const tempAllowance = await loadUsdcAllowanceToDrizzle();
            setAllowance(tempAllowance);
            return tempAllowance;
        } catch(e) {
            console.log("Failing to fetch allowance: ", e);
        }
    }

    function isAllowanceSufficient() {
        return allowance >= depositInEthForm;
    }

    function handleDeposit() {
        (async () => {
            await depositDrizzle(props.recipientAddress, depositInEthForm);
            if (props.onDeposit) props.onDeposit();
        })().catch(console.error);
    }

    function handleApprove() {
        (async () => {
            await approveUsdcToDrizzle(depositInEthForm);
            await loadAllowance();
        })().catch(console.error);
    }

    React.useEffect(() => {
        (async () => {
            if (isConnected) {
                try {
                    await loadAllowance();
                } catch(e) {
                    console.log("UseEffect in deposit buttons: failing to fetch allowance", e);
                }
            }
        })();
    }, [isConnected])

    return(
        <div className={styles['wrapperDeposit']}>
            <div className={styles['formDeposit']}>
                <div className={styles['button']}>
                    {!isConnected &&
                    <ButtonConnectMetamask
                        captionType='connect'
                        onRejected={() => setMetamaskRejected(true)}
                        onSuccess={() => setMetamaskRejected(false)}
                    />}
                    {metamaskRejected && (
                        <div className={styles['cancel-request']}>
                            <Attention color='red'>
                                You rejected the wallet connection. Please approve in Metamask
                            </Attention>
                        </div>
                    )}
                    {isConnected && (
                        <>
                            {!isAllowanceSufficient() && (
                                <>
                                    <div className={styles['attention']}>
                                        <Attention color='blue'>
                                            Insufficient allowance. Drizzle needs your approval to move funds
                                            in order to make a deposit. Please accept in Metamask
                                        </Attention>
                                    </div>
                                    <Button
                                        size='l'
                                        type='primary'
                                        onClick={props.previewMode ? () => {} : handleApprove}
                                        disabled={props.previewMode}
                                    >
                                        Set allowance
                                    </Button>
                                </>
                            )}
                            <Button
                                size='l'
                                type='primary'
                                onClick={props.previewMode ? () => {} : handleDeposit}
                                disabled={!isAllowanceSufficient() || props.previewMode}
                            >
                                Make a ${props.depositHumanUsd} deposit
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}