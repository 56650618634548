import styles from './PopupAddMetamask.module.scss'
import { Modal } from 'components/Modal/Modal'
import { Icon } from 'components/Icon/Icon'
import { Button } from 'components/Button/Button'
import { Link } from 'react-router-dom'

type Props = {
    onClose: () => void,
}

export function PopupAddMetamask(props: Props) {
    return (
        <Modal onClose={props.onClose}>
            <form className={styles['wrapper']}>
                <div className={styles['saving']}>
                    <Icon name='metamask_png' />
                </div>
                <div>
                    <div className={styles['content']}>
                        <span className={styles['title']}>Your browser doesn't have Metamask</span>
                        <span className={styles['description']}>
                            In order to continue, you will need to
                            <Link to='https://support.metamask.io/getting-started/getting-started-with-metamask/'
                                  className={styles['link']}>install Metamask</Link>.
                        </span>
                    </div>
                </div>
                <div className={styles['button']}>
                    <Button size='l' type='dark' onClick={props.onClose}>
                        Got it 👍
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

