import React from 'react'
import styles from './Support.module.scss'
import { Wrapper } from 'components/Wrapper/Wrapper'
import { WelcomeBlock } from 'components/WelcomeBlock/WelcomeBlock'
import { Deposit } from './components/Deposit/Deposit'
import { FAQ } from './components/FAQ/FAQ'
import { Dashboard } from 'components/Dashboard/Dashboard'
import { useSupportPageData } from './useSupportPageData'
import { PreviewSign } from "../../components/PreviewSign/PreviewSign"
import { useIsMobile } from 'hooks/useIsMobile'

const PAGE_TITLE = "Support whom you love! | Drizzle. Sponsor without spending";
const PAGE_TITLE_PREVIEW = "Support whom you love! Preview. | Drizzle. Sponsor without spending";

type Props = {
    isPreview?: boolean;
}

export function Support(props: Props) {
    const { tableData, tvl, totalDrizzles, uniqueBeneficiaries, triggerReload } = useSupportPageData(props.isPreview);
    const { isMobile } = useIsMobile()

    React.useEffect(() => {
        document.title = props.isPreview ? PAGE_TITLE_PREVIEW : PAGE_TITLE;
    }, []);

    const formAnalytics = () => {
        return [
            {
                id: 'tvl',
                name: 'Total value',
                number: tvl.toString(),
                description: 'Total value deposited',
                helpText: 'Combined amount of money you committed to Drizzle. Your beneficiaries receive interest from that amount',
            },
            {
                id: 'deposits',
                name: 'Total deposits',
                number: totalDrizzles.toString(),
                description: 'Total deposits',
                helpText: 'The amount of drizzles you\'ve sent',
                isCurrency: false,
            },
            {
                id: 'beneficiaries',
                name: 'Total beneficiaries',
                number: uniqueBeneficiaries.length.toString(),
                description: 'Unique people you drizzled',
                helpText: 'This indicator reflects how generous you are',
                isCurrency: false,
            },
            {
                id: 'interest',
                name: 'Current interest rate',
                number: "18.86%",
                description: 'Daily interest (testnet)',
                helpText: 'One can hope',
                isCurrency: false,
            },
        ]
    }


    if (isMobile) {
        return (
            <>
                <div className={styles['mobile']}>
                    <div className={styles['mobile__content']}>
                        <div className={styles['welcome']}>
                            <WelcomeBlock text='Now you can make a deposit, and send a link to someone — he will claim an interest from your deposit.' />
                        </div>
                        <Deposit onDeposit={triggerReload} isPreview={props.isPreview} />
                        <FAQ />
                        <Dashboard
                            title={"Beneficiaries"}
                            data={tableData}
                            analytics={formAnalytics()}
                            balance={BigInt(0)}
                            onWithdraw={triggerReload}
                        />
                    </div>
                </div>
            </>
        )
    }

    return (
        <Wrapper>
            <div className={styles['container']}>
                {props.isPreview && <PreviewSign isOnFullPage={true} />}
                <div className={styles['info']}>
                    <div className={styles['welcome']}>
                        {tvl == 0 && <WelcomeBlock text='Now you can make a deposit, and send a link to someone — he will claim an interest from your deposit.' />}
                        {tvl !== 0 && <WelcomeBlock text='You can track the status of your deposits on the dashboard. Or send out another one!' />}
                    </div>
                    <div className={styles['content']}>
                        <Deposit onDeposit={triggerReload} isPreview={props.isPreview} />
                        <FAQ />
                    </div>
                </div>
                <Dashboard
                    title={"Beneficiaries"}
                    data={tableData}
                    analytics={formAnalytics()}
                    balance={BigInt(0)}
                    onWithdraw={triggerReload}
                />
            </div>
        </Wrapper>
    );
}