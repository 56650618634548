import React from 'react'
import styles from './Withdraw.module.scss'
import { Input } from 'components/Input/Input'
import { Button } from 'components/Button/Button'
import classnames from 'classnames'
import { WITHDRAW_DATA_MOCK } from '../consts'

type Props = {
    onSubmit?: (value: number | null) => void,
    deposit: number,
    setDeposit: (value: number) => void,
    isPreview?: boolean,
}

export function Withdraw(props: Props) {
    const [currentTag, setCurrentTag] = React.useState(null)

    function handleActiveTag(tag) {
        setCurrentTag(tag.id)
        props.setDeposit(tag.value)
    }

    function handleChange(item) {
        props.setDeposit(item.value)
        setCurrentTag(null)
    }

    function handleSubmit() {
        props.onSubmit(props.deposit)
    }

    return (
        <div className={styles['wrapper']}>
            <div className={styles['tags']}>
                {WITHDRAW_DATA_MOCK.map((tag) => {
                    return (
                        <div className={
                            classnames({
                                [styles['tag']]: true,
                                [styles['tag_active']]: currentTag === tag.id,
                            })}
                            onClick={() => handleActiveTag(tag)}
                            key={tag.id}>
                            {tag.text}
                        </div>
                    )
                })}
            </div>
            <Input
                size='m'
                type='number'
                name='deposite'
                placeholder='0'
                onChange={handleChange}
                value={props.deposit} />
            <div className={styles['button']}>
                <Button
                    size='m'
                    type='primary'
                    disabled={props.deposit < 1}
                    onClick={handleSubmit}
                    isPreview={props.isPreview}
                >
                    Deposit
                </Button>
            </div>
        </div>
    );
}

