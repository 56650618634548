import styles from './Layout.module.scss'
import {Outlet} from 'react-router-dom'
import { NavBar } from './NavBar/NavBar'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {Web3Provider} from "../components/Web3Context/Web3Context";
import {UserInfoProvider} from "../components/UserInfoContext/UserInfoContext";

type Props = {
    hideNavBar?: boolean;
    showPreviews?: boolean;
}

const redirectIfNoMetamask = () => {
    // window.location.assign('/auth/login');
    return;
}

const redirectIfRejected = () => {
    // window.location.assign('/auth/login');
    return;
}

export function Layout(props: Props) {
    return (
        <Web3Provider loginRejectedCallback={redirectIfRejected} noMetamaskCallback={redirectIfNoMetamask}>
            <UserInfoProvider>
                <div className={styles['wrapper']}>
                    {!props.hideNavBar &&
                        <div className={styles['layout']}>
                            <NavBar showPreviews={props.showPreviews} />
                        </div>
                    }
                    <Outlet />
                    <ToastContainer style={{ minWidth: "480px"}} />
                </div>
            </UserInfoProvider>
        </Web3Provider>
    );
}

