import React, {createContext, useContext, useState, ReactNode} from 'react';
import { ethers } from 'ethers';
import {formDrizzlesMap, signClaimMessage, switchNetwork} from "../helpers";
import {notify, notifyInProgress} from "../../layout/Notification";
import {toast} from "react-toastify";
import { USDC_DECIMALS, USDC_ABI, USDC_ADDRESS, DRIZZLE_ABI, DRIZZLE_ADDRESS } from "../../consts/contracts";

const ERR_NO_METAMASK = 1;
const COMPOUND_PROTOCOL_NAME = "CompoundV2";

interface Web3ProviderProps {
    children: ReactNode;
    loginRejectedCallback: () => void;
    noMetamaskCallback: () => void;
}

interface Web3Contracts {
    drizzle?: ethers.BaseContract;
    usdc?: ethers.BaseContract;
}

export type ConnectMetamaskResponse = "success" | "rejected" | "nometamask";

const Web3Context = createContext<any>(null);

export const Web3Provider: React.FC<Web3ProviderProps> = ({ loginRejectedCallback, noMetamaskCallback, children }) => {
    const [provider, setProvider] = useState<ethers.Provider | null>(null);
    const [signer, setSigner] = useState<ethers.Signer | null>(null);
    const [contracts, setContracts] = useState<Web3Contracts>({});
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [userAddress, setUserAddress] = useState(null);

    const checkForMetamaskAndRedirect = async () => {
        if (!window.ethereum) {
            noMetamaskCallback();
            return ERR_NO_METAMASK;
        }
    }

    const connectWallet = async () => {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });

            if (addressArray.length > 0) {
                const prov = new ethers.BrowserProvider(window.ethereum);
                setProvider(prov);

                const sign = await prov.getSigner();
                setSigner(sign);

                setUserAddress(sign.address);

                return { prov, sign };
            } else return null;
        } catch (e) {
            loginRejectedCallback();
        }
    }

    const setupContracts = async (prov: ethers.Provider, sign: ethers.Signer) => {
        const drizzle = new ethers.Contract(DRIZZLE_ADDRESS, DRIZZLE_ABI, prov);
        const drizzleWithSigner = drizzle.connect(sign);
        const usdc = new ethers.Contract(USDC_ADDRESS, USDC_ABI, prov);
        const usdcWithSigner = usdc.connect(sign);
        setContracts({
            drizzle: drizzleWithSigner,
            usdc: usdcWithSigner,
        })
    }

    async function setupWeb3State(callbackConnected?: () => void): Promise<ConnectMetamaskResponse> {
        if (await checkForMetamaskAndRedirect() === ERR_NO_METAMASK) {
            return "nometamask";
        }
        const conn = await connectWallet();
        if (conn) {
            const {prov, sign } = conn;
            await switchNetwork();
            await setupContracts(prov, sign);
            setIsConnected(true);
            if (callbackConnected) callbackConnected();
            return "success";
        } else {
            return "rejected";
        }
    }

    async function connectWeb3(callbackConnected?: () => void): Promise<ConnectMetamaskResponse> {
        return (await setupWeb3State(callbackConnected));
    }

    const usdcHumanToEth = (num: number) => {
        return ethers.parseUnits(String(num), USDC_DECIMALS);
    }

    const usdcEthToHuman = (num: number) => {
        return ethers.formatUnits(String(num), USDC_DECIMALS);
    }

    const loadUsdcAllowanceToDrizzle = async () => {
        // @ts-ignore
        return (await contracts.usdc.allowance(userAddress, contracts.drizzle.target));
    }

    const approveUsdcToDrizzle = async (deposit: bigint) => {
        // @ts-ignore
        const tx = await contracts.usdc.approve(contracts.drizzle.target, deposit);
        const notifyId = notifyInProgress(
            'Setting up allowance...',
            'Let\'s wait for the allowance transaction to go through'
        );
        await tx.wait();
        toast.dismiss(notifyId);
        notify(
            'Allowance is set up',
            'Now you can make a drizzle'
        );
    }

    const depositDrizzle = async (address: ethers.AddressLike, deposit: bigint) => {
        // @ts-ignore
        const tx = await contracts.drizzle.addPrincipal(address, contracts.usdc.target, COMPOUND_PROTOCOL_NAME, deposit);
        const notifyId = notifyInProgress(
            'Creating a drizzle...',
            'Waiting for the transaction to go through'
        );
        await tx.wait();
        toast.dismiss(notifyId);
        notify(
            'Deposit is made',
            'Your drizzle is on its way'
        );
    }

    const withdrawInterest = async () => {
        // @ts-ignore
        const tx = await contracts.drizzle.withdrawInterest(contracts.usdc.target, COMPOUND_PROTOCOL_NAME);
        const notifyId = notifyInProgress('Withdrawing interest...', 'Waiting for the transaction to go through');
        await tx.wait();
        toast.dismiss(notifyId);
        notify('Withdrawal is made', 'Your interest was sent to your address');
    }

    const withdrawDrizzle = async (beneficiary: ethers.AddressLike, amount: bigint) => {
        // @ts-ignore
        const tx = await contracts.drizzle.withdrawPrincipal(beneficiary, contracts.usdc.target, COMPOUND_PROTOCOL_NAME, amount);
        const notifyId = notifyInProgress('Withdrawing your drizzle...', 'Waiting for the transaction to go through');
        await tx.wait();
        toast.dismiss(notifyId);
        notify('Drizzle retracted', 'Your deposit was sent to your address');
    }

    const claim = async (sender: ethers.AddressLike, claimWallet: ethers.Wallet) => {
        const {v, r, s, } =
            await signClaimMessage(
                claimWallet,
                userAddress as string,
                String(contracts.usdc.target),
                COMPOUND_PROTOCOL_NAME
            );
        //     address[] calldata _senders,
        //     address _newBeneficiary,
        //     address _token,
        //     string calldata _yieldProtocol,
        //     uint8 _v,
        //     bytes32 _r,
        //     bytes32 _s
        // @ts-ignore
        const tx = await contracts.drizzle.claimBeneficiaryPositionsFrom(
            [sender],
            userAddress,
            contracts.usdc.target,
            COMPOUND_PROTOCOL_NAME,
            v,
            r,
            s
        );
        const notifyId = notifyInProgress(
            'Claiming the drizzle...',
            'Waiting for the transaction to go through'
        );
        await tx.wait();
        toast.dismiss(notifyId);
        notify(
            'Drizzle is claimed!',
            'You can see it on your dashboard now'
        );
    }

    const viewBeneficiaryTvl = async (address: ethers.AddressLike) => {
        // @ts-ignore
        const beneficiaryPosition =
            // @ts-ignore
            await contracts.drizzle.viewBeneficiaryPosition(address, contracts.usdc.target, COMPOUND_PROTOCOL_NAME);
        return beneficiaryPosition[0];
    }

    const viewPatronsDepositIntoBeneficiary = async (patron: ethers.AddressLike, beneficiary: ethers.AddressLike) => {
        // @ts-ignore
        return (await contracts.drizzle.viewPrincipal(patron, beneficiary, contracts.usdc.target, COMPOUND_PROTOCOL_NAME));
    }

    const viewBalance = async (address: ethers.AddressLike) => {
        // @ts-ignore
        return (await contracts.drizzle.viewInterest(address, contracts.usdc.target, COMPOUND_PROTOCOL_NAME));
    }

    const loadDrizzlesListFromTo = async (from?: ethers.AddressLike, to?: ethers.AddressLike) => {
        return (await formDrizzlesMap(contracts.drizzle, from, to));
    }

    const autoConnectIfMetamaskAvailable = async () => {
        // @ts-ignore
        if (window.ethereum && window.ethereum._state.accounts && window.ethereum._state.accounts.length > 0) {
            await setupWeb3State();
            return true;
        } else {
            return false;
        }
    }

    React.useEffect(() => {
        // We're trying to wait until Metamask gets loaded on the page (which it doesn't announce)
        // and query it non-invasively. So we use spaced repetition to check three times after progressively longer delays
        const FIRST_DELAY  = 100;
        const SECOND_DELAY = 350;
        const THIRD_DELAY  = 800;
        const checkMetamask = async (counter: number) => {
            const result = await autoConnectIfMetamaskAvailable();
            if (!result) {
                if (counter === 0) {
                    setTimeout(() => checkMetamask(1), SECOND_DELAY);
                } else if (counter === 1) {
                    setTimeout(() => checkMetamask(2), THIRD_DELAY);
                }
            }
        };

        setTimeout(() => checkMetamask(0), FIRST_DELAY);
    }, []);

    return (
        <Web3Context.Provider value={{
            provider, signer, contracts, userAddress,
            usdcEthToHuman, usdcHumanToEth,
            connectWeb3, isConnected, checkForMetamaskAndRedirect,
            viewBeneficiaryTvl, viewPatronsDepositIntoBeneficiary, viewBalance, loadUsdcAllowanceToDrizzle, loadDrizzlesListFromTo,
            approveUsdcToDrizzle, claim, depositDrizzle, withdrawInterest, withdrawDrizzle
        }}>
            {children}
        </Web3Context.Provider>
    );
};

export const useWeb3 = () => useContext(Web3Context);