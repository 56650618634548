import styles from "./PreviewSign.module.scss";
import {Icon} from "../Icon/Icon";
import React from "react";

type Props = {
    isOnFullPage?: boolean
}

export function PreviewSign(props: Props) {
    return (
        <div className={props.isOnFullPage ? styles['preview__logo__full'] : styles['preview__logo']}>
            <Icon name='preview'/>
            <span>Preview</span>
        </div>
    )
}

