import { Icon } from 'components/Icon/Icon'
import { ReactNode, useEffect } from 'react'
import styles from './ModalPage.module.scss'
import classNames from 'classnames'

type Props = {
    children: ReactNode,
    onClose?: () => void,
    hideCross: boolean,
    className?: string
}

export function ModalPage(props: Props) {

    useEffect(() => {
        // Отключаем прокрутку при открытии модального окна
        document.body.style.overflow = 'hidden';

        // Восстанавливаем прокрутку при закрытии модального окна
        return () => {
            document.body.style.overflow = '';
        };
    }, [])

    return (
        <div className={classNames(styles['modal'], props.className)}>
            {!props.hideCross && (
                <div className={styles['modal__navbar']}>
                    <div className={styles['cross']} onClick={props.onClose}>
                        <Icon name='cross' />
                    </div>
                </div>
            )}
            <div className={styles['wrapper']}>
                <div className={styles['form']}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

ModalPage.defaultProps = {
    hideCross: false
};

