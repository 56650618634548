import React from 'react'
import styles from './Deposit.module.scss'
import { FormDeposit } from 'components/FormDeposit/FormDeposit'
import {EmptyUser} from "shared/db-types";


export function Deposit() {
    const [user, setUser] = React.useState(EmptyUser)

    return (
        <div className={styles['wrapper']}>
            <FormDeposit user={user} />
        </div>
    );
}

