import React from 'react'
import styles from './PublicProfile.module.scss'
import {FormDeposit} from 'components/FormDeposit/FormDeposit'
import {THEME_COLORS} from 'components/PopupEditPage/EditPage/FormEdit/consts'
import {BackgroundColor, User} from "shared/db-types";
import {PopupDepositPlaced} from "../../components/PopupDepositPlaced/PopupDepositPlaced";
import {useNavigate} from "react-router-dom";

type Props = {
    user: User;
}

export function PublicProfile(props: Props) {
    const [isPopup, setIsPopup] = React.useState(false);

    const PAGE_TITLE = props.user.username + " | Drizzle. Sponsor without spending";

    const navigate = useNavigate()

    const openPopup = () => {
        setIsPopup(true);
    }

    const closePopup = () => {
        setIsPopup(false);
        navigate('/support');
    }

    React.useEffect(() => {
        document.title = PAGE_TITLE;
    }, []);

    return (
        <div className={styles['wrapper']}
            style={{ backgroundColor: THEME_COLORS[!!props.user.background ? props.user.background : BackgroundColor.Orange] }}>
             <div className={styles['hug-box']}>
                <FormDeposit user={props.user} onDeposit={openPopup} />
            </div>
            {isPopup && <PopupDepositPlaced onClose={closePopup} username={props.user.username} />}
        </div>
    )
}
