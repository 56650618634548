import React from 'react';
import { Icon } from 'components/Icon/Icon'
import styles from './UserNotFound.module.scss'
import {Wrapper} from "../../components/Wrapper/Wrapper";

const PAGE_TITLE = "Oh, no! Where is everybody? | Drizzle. Sponsor without spending";

export function UserNotFound() {
    React.useEffect(() => {
        document.title = PAGE_TITLE;
    }, []);

    return (
        <>
            <Wrapper>
                <div className={styles['container']}>
                    <div className={styles['form']}>
                        <div>
                            <Icon name='pig' />
                        </div>
                        <h1 className={styles['title']}>
                            404! Can't find a user by this link!
                        </h1>
                        <div>
                            <article className={styles['item']}>
                    <span>
                        That's just sad. Wish we could help!
                    </span>
                            </article>
                            <article className={styles['item']}>
                    <span>
                        Would you mind going back and trying to go at it another way?
                    </span>
                            </article>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    )
}

