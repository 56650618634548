import styles from './UserInfo.module.scss'
import profile from 'assets/image50.png'
import classnames from 'classnames'
import { Icon } from 'components/Icon/Icon'
import { User } from 'shared/db-types';

type Props = {
    user: User,
    isPublic?: boolean,
}

const FB_LINK_PREFIX = "https://facebook.com/";
const TWITTER_LINK_PREFIX = "https://x.com/";
const INSTA_LINK_PREFIX = "https://instagram.com/";
const position = '17'

export function UserInfo(props: Props) {

    const networksClassName = classnames({
        [styles['networks']]: true,
        [styles['networks_disabled']]: !props.isPublic
    })

    const gotoUrl = (address: string) => {
        window.open(address, "_blank");
    }

    return (
        <div className={styles['container']}>
            <div className={styles['wrapper']}>
                <div className={styles['imgWrapp']}>
                    {position && (
                        <div className={styles['star']}>
                            <Icon name='star_bg' />
                            <div className={styles['position']}>
                                #{position}
                            </div>
                        </div>)}
                    <img src={profile} className={styles['img']} alt='logo_mini2' />
                </div>
                <div className={styles['info']}>
                    <div>
                        <div className={styles['info__name']}>{props.user.username}</div>
                        <div className={styles['info__analytic']}>
                            {/* {props.user.patrons && <span><b>{props.user.patrons}</b> patrons</span>}
                            {props.user.drizzles && <span><b>${props.user.drizzles}</b> drizzles</span>} */}
                        </div>
                        <div className={styles['description']}>
                            {props.isPublic ? props.user.bio : <Icon name='skeleton' />}
                        </div>
                    </div>
                    <div className={networksClassName}>
                        {props.user.facebook &&
                            <div className={styles['network']}
                                onClick={() => gotoUrl(FB_LINK_PREFIX + props.user.facebook)}>
                                <Icon name='facebook' />
                            </div>
                        }
                        {props.user.twitter &&
                            <div className={styles['network']}
                                onClick={() => gotoUrl(TWITTER_LINK_PREFIX + props.user.twitter)}>
                                <Icon name='twitter' />
                            </div>
                        }
                        {props.user.instagram &&
                            <div className={styles['network']}
                                onClick={() => gotoUrl(INSTA_LINK_PREFIX + props.user.instagram)}>
                                <Icon name='instagram' />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

UserInfo.defaultProps = {
    isPublic: true,
}



