import React from 'react'
import styles from './Cover.module.scss'
import { Icon } from 'components/Icon/Icon'
import classnames from 'classnames'
import { COVER_DATA } from './consts'


type Props = {
    label?: string,
    onChange: (value: string) => void,
    name: string,
};

export function Cover(props: Props) {
    const containerRef = React.useRef(null);
    const [isOpen, setIsOpen] = React.useState(false)
    const [selectedItem, setSelectedItem] = React.useState(COVER_DATA[0])

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    function handleItemClick(item) {
        setSelectedItem(item)
        props.onChange(item)

        setIsOpen(false)
    }

    const handleOutsideClick = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsOpen(false)
        }
    }

    React.useEffect(() => {
        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [])

    const inputClassNames = classnames(
        styles['input'],
        styles[`input_${selectedItem.style}`]
    )

    const iconClassNames = classnames({
        [styles['icon']]: true,
        [styles['icon_active']]: isOpen
    })

    return (
        <div className={styles['wrapper']} ref={containerRef}>
            <label htmlFor={props.name} className={styles['label']}>
                {props.label}
            </label>
            <div
                className={inputClassNames}
                id={props.name}
                onClick={toggleDropdown}
            >
                <div className={iconClassNames}>
                    <Icon name='short_arrow_down' />
                </div>
            </div>
            {isOpen && (
                <div className={styles['content']}>
                    {COVER_DATA.map((item) => {
                        return (
                            <div
                                className={styles['imgWrapper']}
                                key={item.id}
                                onClick={() => handleItemClick(item)}>
                                <img
                                    src={item.value}
                                    className={styles['img']}
                                    alt={item.title}
                                />
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    );
};
