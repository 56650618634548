import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from './router/RouterProvider'
import { BrowserRouter } from "react-router-dom"
import reportWebVitals from './reportWebVitals'
import 'index.css'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <RouterProvider />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
