import styles from './SignupStep3Creator.module.scss'
import { Button } from 'components/Button/Button'
import { useNavigate } from 'react-router-dom'
import {useWeb3} from "../../../../../components/Web3Context/Web3Context";
import {useDBUser} from "../../../../../components/UserInfoContext/UserInfoContext";


type Props = {
    onCreatePublicPage?: () => void
    step?: number,
}

export function SignupStep3Creator(props: Props) {
    const navigate = useNavigate()
    const { userAddress } = useWeb3();
    const { user, saveUserToDB } = useDBUser();

    function handlePublic() {
        props.onCreatePublicPage();
    }

    async function handleAnonymous() {
        const updUser = {
            ...user,
            link: userAddress.toLowerCase()
        };
        await saveUserToDB(updUser);
        navigate('/request')
    }

    return (
        <div className={styles['wrapper']}>
            <div className={styles['title']}>
                Your Drizzle page
            </div>
            <div className={styles['description']}>
                To claim donations — create your public page and share it in social media.
                <br />
                If you want to stay anonymous — stay.
                <br />
                You can public your page lately.
            </div>
            <div className={styles['buttons']}>
                <Button
                    size='l'
                    type='dark'
                    iconName='forward'
                    iconRight
                    onClick={handlePublic}
                >
                    Create public page
                </Button>
                <Button
                    size='l'
                    type='dark'
                    iconName='forward'
                    iconRight
                    onClick={handleAnonymous}
                >
                    Create anonymous profile
                </Button>
            </div>
        </div >
    );
}

