import gray from 'assets/cover_gray.png'
import yellow from 'assets/cover_yellow.png'
import green from 'assets/cover_green.png'
import blue from 'assets/cover_blue.png'


export const COVER_DATA = [
    {
        id: '2',
        title: 'drizzle yellow',
        style: 'yellow',
        value: yellow
    },
    {
        id: '1',
        title: 'drizzle gray',
        style: 'gray',
        value: gray
    },
    {
        id: '4',
        title: 'drizzle blue',
        style: 'blue',
        value: blue
    },
    {
        id: '3',
        title: 'drizzle green',
        style: 'green',
        value: green
    },
]