import React from 'react'
import styles from './Balance.module.scss'
import { Icon } from 'components/Icon/Icon'
import { Button } from 'components/Button/Button'
import { PopupBalance } from './PopupBalance/PopupBalance'
import { formatNumberWithCommas } from "components/helpers"

type Props = {
    amount: bigint,
    isTvlZero: boolean,
    reloadFn: () => void,
    isPreview?: boolean;
}

export function Balance(props: Props) {
    const [showPopup, setShowPopup] = React.useState(false)

    const isBalanceZero = props.amount === BigInt(0);

    function closePopup() {
        props.reloadFn();
        setShowPopup(false)
    }

    function openPopup() {
        setShowPopup(true)
    }

    return (
        <>
            <div className={styles['wrapper']}>
                <div className={styles['content']}>
                    <div className={styles['balance']}>
                        <div className={styles['coinWrapp']}>
                            <Icon name='coin' />
                            <span className={styles['balance__label']}>Balance</span>
                        </div>
                        <span className={styles['balance__amount']}>{formatNumberWithCommas('$', String(props.amount))}</span>
                    </div>
                    <Button
                        size='m'
                        type='dark'
                        onClick={openPopup}
                        disabled={isBalanceZero}
                        isPreview={props.isPreview}
                    >
                        Withdraw all
                    </Button>
                    {isBalanceZero ? (
                        <>
                            {props.isTvlZero ? (
                                <div className={styles['error']}>
                                    <div>
                                        <Icon name='attention' />
                                    </div>
                                    <span className={styles['error__text']}>Your balance is zero because you have no Patrons.
                                        Your Patrons' deposits will generate balance.</span>
                                </div>
                            ) : (
                                <span className={styles['info']}>Your balance is currently zero, but you have some deposits.
                                    Come back later to check how much they've generated!</span>
                            )
                            }
                        </>
                    ) : (
                        <span className={styles['info']}>Your Patrons' deposits will continue generating new earnings</span>
                    )}
                </div>
            </div>
            {showPopup && <PopupBalance onClose={closePopup} balance={props.amount} />}
        </>
    )
}

