import React from "react";
import { SUPPORT_MOCK_DATA } from './consts'
import { useWeb3 } from "../../components/Web3Context/Web3Context";
import { onlyUnique } from "../../components/helpers";
import SecureLocalStorage from "../../components/SecureLocalStorage";

export function useSupportPageData(useMockData: boolean = false) {
    const [tableData, setTableData] = React.useState(SUPPORT_MOCK_DATA);
    const [tvl, setTvl] = React.useState(0);
    const [totalDrizzles, setTotalDrizzles] = React.useState(0);
    const [uniqueBeneficiaries, setUniqueBeneficiaries] = React.useState([]);
    const [refreshCounter, setRefreshCounter] = React.useState(0);

    const { isConnected, userAddress, loadDrizzlesListFromTo, viewPatronsDepositIntoBeneficiary, usdcEthToHuman } = useWeb3();

    const populateDashboard = async () => {
        if (useMockData) {
            return;
        }
        try {
            const beneficiaries = await loadDrizzlesListFromTo(userAddress, null);

            let dashboardList = [];

            beneficiaries.forEach((val, ) => {
                dashboardList.push({
                    beneficiary: val.beneficiary,
                    currency:    val.currency,
                    deposit:     val.deposit,
                    action:      "stub",
                });
            });

            dashboardList = dashboardList.filter((ben) => ben.deposit > 0);

            setTableData({
                ...tableData,
                patrons: dashboardList,
            });
            const uniqueBen = dashboardList.map((e) => e.beneficiary).filter(onlyUnique);
            setUniqueBeneficiaries(uniqueBen);

            setTotalDrizzles(dashboardList.length);

            const promises = uniqueBen.map(async (b) => await viewPatronsDepositIntoBeneficiary(userAddress, b));
            const vals: bigint[] = await Promise.all(promises);
            const bigSum = vals.reduce((acc, x) => acc + x, BigInt(0)).toString();
            setTvl(Number(usdcEthToHuman(bigSum)));

            await cleanupClaimLinks(dashboardList);
        } catch (e) {
            console.log("Failing to populate the dashboard", e);
        }
    }

    const cleanupClaimLinks = async (tableData) => {
        const allBeneficiaries = tableData.map((i) => i.beneficiary);
        const allClaimLinks = await SecureLocalStorage.getAllClaimLinks();
        allClaimLinks.map(async (cl) => {
            if (!allBeneficiaries.includes(cl.address)) {
                await SecureLocalStorage.removeClaimLink(cl.address);
            }
        })
    }

    const triggerReload = () => {
        setRefreshCounter(refreshCounter + 1);
    }

    React.useEffect(() => {
        if (isConnected && !useMockData) {
            (async () => {
                await populateDashboard();
            })();
        }
    }, [isConnected, refreshCounter]);

    return {
        tableData:           useMockData ? SUPPORT_MOCK_DATA : tableData,
        tvl:                 useMockData ? '2050'            : tvl,
        totalDrizzles:       useMockData ? '5'               : totalDrizzles,
        uniqueBeneficiaries: useMockData ? [1,2,3,4,5]       : uniqueBeneficiaries,
        triggerReload
    };
}