import React from 'react'
import { Icon } from 'components/Icon/Icon'
import styles from './UserMenu.module.scss'
import userpickimg from 'assets/userpickimg.png'
import { formatWalletAddress } from 'layout/NavBar/helpers'
import {useWeb3} from "../../../../components/Web3Context/Web3Context";
import {useDBUser} from "../../../../components/UserInfoContext/UserInfoContext";

type Props = {
    onOpenEditPage?: () => void,
    onLogout?: () => void,
}

export function UserMenu(props: Props): JSX.Element {
    const [showMenu, setShowMenu] = React.useState(false)
    const containerRef = React.useRef(null)

    const { isConnected, connectWeb3, userAddress } = useWeb3();
    const { user } = useDBUser();

    function openMenu() {
        setShowMenu(!showMenu)
    }

    const handleOutsideClick = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    }

    React.useEffect(() => {
        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, [])

    /*
    // Usage of account delete button
        {account && (
            <div className={styles['menu__account']} onClick={() => { }}>
                <div className={styles['menu__account__container']}>
                    <Icon name='wallet' />
                    <span>{formatWalletAddress(account)}</span>
                </div>
                <div className={styles['delete']} onClick={handleDeleteAccount}>
                    <Icon name='delete' />
                </div>
            </div>
        )}

        //also
        <span className={styles['mail']}>{props.data.mail}</span>
     */

    return (
        <div className={styles['wrapper']} ref={containerRef}>
            <div className={styles['button']} onClick={openMenu}>
                <img src={userpickimg} alt='user' />
            </div>
            {showMenu && (
                <div className={styles['menu']}>
                    <div className={styles['menu__header']}>
                        <img className={styles['img']} src={userpickimg} alt='user' />
                        <div className={styles['content']}>
                            <span className={styles['name']}>{user.username}</span>
                        </div>
                    </div>
                    {isConnected && (
                        <div className={styles['menu__account']} onClick={() => { }}>
                            <div className={styles['menu__account__container']}>
                                <Icon name='wallet' />
                                <span>{formatWalletAddress(userAddress)}</span>
                            </div>
                        </div>
                    )}
                    {!isConnected && (
                        <div className={styles['menu__item']} onClick={connectWeb3}>
                            <Icon name='add' />
                            <span>Connect wallet</span>
                        </div>
                    )}
                    <div className={styles['menu__item']} onClick={props.onOpenEditPage}>
                        <Icon name='edit' />
                        <span>Edit profile</span>
                    </div>
                </div>
            )}
        </div>
    );
}

