import React from 'react'
import styles from './Dashboard.module.scss'
import { Analytics } from './Analytics/Analytics'
import { Table } from './Table/Table'
import { Icon } from 'components/Icon/Icon'
import { AnalyticsType, DashboardData } from './consts'
import { PopupWithdraw } from './PopupWithdraw/PopupWithdraw'
import { ethers } from "ethers";


type Props = {
    data: DashboardData,
    analytics: AnalyticsType[],
    balance: bigint,
    title: string,
    onWithdraw?: () => void,
}

export function Dashboard(props: Props) {
    const [showPopup, setShowPopup] = React.useState(false);
    const [withdrawFrom, setWIthdrawFrom] = React.useState<ethers.AddressLike>('0x0');
    const [withdrawBalance, setWithdrawBalance] = React.useState<bigint>(BigInt(0));

    function closePopup() {
        setShowPopup(false);
    }

    function onWithdraw() {
        props.onWithdraw();
        closePopup();
    }

    function openPopup(beneficiary: ethers.AddressLike, balance: bigint) {
        setWIthdrawFrom(beneficiary);
        setWithdrawBalance(balance);
        setShowPopup(true);
    }

    return (
        <>
            <div className={styles['wrapper']} >
                <Analytics analytics={props.analytics} />
                <div className={styles['content']}>
                    <div className={styles['container']}>
                        <span className={styles['title']}>{props.title}</span>
                        <div className={styles['patrons']}>
                            <Icon name='patrons' />
                        </div>
                    </div>
                    <Table data={props.data} onOpenWithdraw={openPopup} />
                </div>
            </div>
            {showPopup &&
                <PopupWithdraw
                    balance={withdrawBalance}
                    beneficiary={withdrawFrom}
                    onClose={closePopup}
                    onWithdraw={onWithdraw}
                />}
        </>
    );
}

