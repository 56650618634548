import React from 'react'
import styles from './EditPage.module.scss'
import {FormDeposit} from 'components/FormDeposit/FormDeposit'
import {FormEdit} from './FormEdit/FormEdit'
import {Icon} from 'components/Icon/Icon'
import {THEME_COLORS} from './FormEdit/consts'
import {EditNavBar} from './EditNavBar/EditNavBar'
import {BackgroundColor, EmptyUser, User} from "shared/db-types";
import {useDBUser} from "../../UserInfoContext/UserInfoContext";
import {PreviewSign} from "../../PreviewSign/PreviewSign";

type Props = {
    children?: React.ReactNode,
    onClose: () => void,
}

export function EditPage(props: Props) {
    const theme = "pink";
    const [isDataChanged, setIsDataChanged] = React.useState(false)
    const [editedUser, setEditedUser] = React.useState(EmptyUser);

    const { user, saveUserToDB }  = useDBUser();

    function isUserChanged(original: User, edited: User): boolean {
        for (const key in original) {
            if (original[key as keyof(User)] !== edited[key as keyof(User)]) {
                return true;
            }
        }
        return false;
    }

    async function handleSubmit() {
        await saveUserToDB(editedUser);
        setIsDataChanged(false);
        props.onClose();
    }

    async function handleDiscard() {
        setIsDataChanged(false)
        setEditedUser(user);
    }

    async function handleChange(applyUpdateToUserPurely) {
        const maybeEditedUser = applyUpdateToUserPurely(user);
        setEditedUser(maybeEditedUser);
        setIsDataChanged(isUserChanged(user, maybeEditedUser));
    }

    React.useEffect(() => {
        setEditedUser(user);
    }, []);

    return (
        <div className={styles['wrapper']}>
            <EditNavBar
                onClose={props.onClose}
                onSubmit={handleSubmit}
                onDiscard={handleDiscard}
                isDataChanged={isDataChanged}
                userLink={user.link}
            />
            <div className={styles['edit-content']}>
                <div className={styles['scroll']}>
                    <FormEdit
                        title='Edit page'
                        onChange={handleChange}
                        user={editedUser}
                    />
                </div>
            </div>
            <div
                className={styles['preview']}
                style={{ backgroundColor: THEME_COLORS[!!editedUser.background ? editedUser.background : BackgroundColor.Orange] }}
            >
                <PreviewSign />
                <FormDeposit user={editedUser} previewMode={true} />
            </div>
        </div>
    )
}
