import { ICONS } from './Icons';

export type IconType = 'shopping_cart' |
    'browsers' |
    'arrow_down' |
    'arrow_up' |
    'logout' |
    'eye' |
    'eye_close' |
    'big_lock' |
    'tick' | string;

type Props = {
    name: IconType | string;
    onClick?: () => void;
}

export function Icon(props: Props) {
    return ICONS[props.name];
}
