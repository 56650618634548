import styles from './FormDeposit.module.scss'
import { UserInfo } from 'components/UserInfo/UserInfo'
import { Icon } from 'components/Icon/Icon'
import { User } from "shared/db-types"
import React from "react"
import { DepositButtons } from "../DepositButtons/DepositButtons"
import { WITHDRAW_DATA_MOCK } from "../../pages/Support/components/Deposit/consts"
import classnames from "classnames"
import { Input } from "../Input/Input"

type Props = {
    user: User,
    onDeposit?: () => void,
    previewMode?: boolean,
}

const DEFAULT_DEPOSIT = 10

export function FormDeposit(props: Props) {
    const [currentTag, setCurrentTag] = React.useState(null)
    const [deposit, setDeposit] = React.useState(20)

    function handleActiveTag(tag) {
        setCurrentTag(tag.id)
        setDeposit(tag.value)
    }

    function handleChange(item) {
        setDeposit(item.value)
        setCurrentTag(null)
    }

    function handleInputZero() {
        if (deposit === 0) {
            setDeposit(DEFAULT_DEPOSIT)
        }
    }

    return (
        <div className={styles['container']}>
            <div className={styles['wrapper']}>
                <div className={styles['drizzle_wrapp']}>
                    <div className={styles['drizzle']}>
                        <Icon name='drizzle_preview_mini' />
                    </div>
                    <UserInfo user={props.user} />
                </div>
            </div>
            <div className={styles['bg']}>
                <div className={styles['withdraw']}>
                    <div className={styles['info']}>
                        <span className={styles['title']}>
                            Support me with a Drizzle
                        </span>
                        <span className={styles['description']}>
                            Choose deposit size, and I will get interest
                        </span>
                    </div>
                    <div className={styles['inside-wrapper']}>
                        <div className={styles['tags']}>
                            {WITHDRAW_DATA_MOCK.map((tag) => {
                                return (
                                    <div className={
                                        classnames({
                                            [styles['tag']]: true,
                                            [styles['tag_active']]: currentTag === tag.id,
                                        })}
                                        onClick={() => handleActiveTag(tag)}
                                        key={tag.id}>
                                        {tag.text}
                                    </div>
                                )
                            })}
                        </div>
                        <Input
                            size='m'
                            type='number'
                            name='deposit'
                            placeholder='Or type something up'
                            onChange={handleChange}
                            onBlur={handleInputZero}
                            value={deposit} />
                        <DepositButtons
                            recipientAddress={props.user.address}
                            depositHumanUsd={deposit}
                            onDeposit={props.onDeposit}
                            previewMode={props.previewMode}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

