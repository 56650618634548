export const PATRON_STATUS = {
    WITHDREW: 'WITHDREW',
    CLAIMED: 'CLAIMED',
    UNCLAIMED: 'UNCLAIMED',
    IN_PROGRESS: 'IN_PROGRESS',
    FAILED: 'FAILED'
}

export type ColumnWidth = {
    [columnName: string]: {
        width: string,
        isMobile: boolean
    }
}

export type PatronType = {
    [patrong: string]: string | number;
}

export type TypeHeader = {
    id: string,
    name: string,
    cell: {
        width: string,
        isMobile: boolean
    },
}


export type AnalyticsType = {
    id: string,
    name: string,
    number: string,
    description: string,
    helpText: string,
    isCurrency?: boolean
}

export type DashboardData = {
    headers: TypeHeader[],
    columnWidth: ColumnWidth,
    patrons: PatronType[],
}