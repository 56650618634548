export const WITHDRAW_DATA_MOCK = [
    {
        id: 1,
        text: '$50',
        value: 50,
    },
    {
        id: 2,
        text: '$100',
        value: 100,
    },
    {
        id: 3,
        text: '$500',
        value: 500,
    },
    {
        id: 4,
        text: '$1000',
        value: 1000,
    },
]