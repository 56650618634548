import React from 'react'
import styles from './PopupSendDeposit.module.scss'
import { Icon } from 'components/Icon/Icon'
import { Input } from 'components/Input/Input'
import { Button } from 'components/Button/Button'
import { ModalPage } from 'components/ModalPage/ModalPage'
import { Cover } from 'components/Cover/Cover'
import { COVER_DATA } from 'components/Cover/consts'
import { Attention } from 'components/Attention/Attention'
import { PopupAddExtMetamask } from './PopupAddExtMetamask/PopupAddExtMetamask'
import { createClaimPayload, createClaimUrl, createPkey } from "components/helpers";
import { PopupDepositPlaced } from "components/PopupDepositPlaced/PopupDepositPlaced";
import { useWeb3 } from "../Web3Context/Web3Context";
import SecureLocalStorage from "../SecureLocalStorage";

const PAGE_TITLE = "Send a drizzle! | Drizzle. Sponsor without spending";
const AUTO_SET_DEPOSIT_IF_ZERO = 10;

type Props = {
    onClose: () => void,
    onDeposit: () => void,
    deposit: null | number,
}

export function PopupSendDeposit(props: Props) {
    const { userAddress, approveUsdcToDrizzle, depositDrizzle, loadUsdcAllowanceToDrizzle, usdcHumanToEth } = useWeb3();

    const [credentials, setCredentials] = React.useState({
        deposit: props.deposit,
        name: null,
        img: COVER_DATA[0].value
    })
    const [modalAddMetamask, setModalAddMetamask] = React.useState(false)
    const [name, setName] = React.useState("Anonymous");
    const [allowance, setAllowance] = React.useState(BigInt(0));
    const [deposit, setDeposit] = React.useState(usdcHumanToEth(props.deposit));
    const [showLinkPopup, setShowLinkPopup] = React.useState(false);
    const [claimAddress, setClaimAddress] = React.useState("");
    const [claimPKey, setClaimPKey] = React.useState("");
    const [coverStyle, setCoverStyle] = React.useState("blue");

    function handleCredentials(target) {
        setCredentials({
            ...credentials,
            [target.name]: target.value,
        });
        setDeposit(usdcHumanToEth(target.value));
    }

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
    }

    function handleCover(item) {
        setCredentials({
            ...credentials,
            img: item.value,
        });
        const color = item.title.split(' ')[1];
        console.log("Cover style", color);
        setCoverStyle(color);
    }

    function handleDeposit() {
        (async () => {
            await depositDrizzle(claimAddress, deposit);
            await showClaimLinkPopup();
        })().catch(console.error);
    }

    function handleApprove() {
        (async () => {
            await approveUsdcToDrizzle(deposit);
            await loadAllowance();
        })().catch(console.error);
    }

    function handleNameChange(item) {
        const newName = item.value === "" ? "Anonymous" : item.value;
        setName(newName);
        setCredentials({
            ...credentials,
            name: newName
        })
    }

    function isAllowanceSufficient() {
        return allowance >= deposit;
    }

    async function loadAllowance() {
        try {
            // @ts-ignore
            const tempAllowance = await loadUsdcAllowanceToDrizzle();
            setAllowance(tempAllowance);
            return tempAllowance;
        } catch (e) {
            console.log("Failing to fetch allowance: ", e);
        }
    }

    function closeAddMetamask() {
        setModalAddMetamask(false)
    }

    function handleZeroSum() {
        if (credentials.deposit == 0) {
            setCredentials({
                ...credentials,
                deposit: AUTO_SET_DEPOSIT_IF_ZERO
            });
        }
    }

    async function createClaimAddress() {
        const { address, encryptedPkey } = await createPkey();
        setClaimAddress(address);
        setClaimPKey(encryptedPkey);
    }

    function finalizeClaimPayload(): string {
        return createClaimPayload(
            claimPKey,
            userAddress,
            credentials.name || 'Anonymous',
            credentials.deposit.toString(),
            coverStyle
        );
    }

    function finalizeClaimLink(): string {
        return createClaimUrl(
            claimPKey,
            userAddress,
            credentials.name || 'Anonymous',
            credentials.deposit.toString(),
            coverStyle
        );
    }

    React.useEffect(() => {
        document.title = PAGE_TITLE;
        (async () => {
            await createClaimAddress();
        })();
    }, []);

    async function showClaimLinkPopup() {
        props.onDeposit();
        await SecureLocalStorage.storeClaimLink(claimAddress, finalizeClaimPayload());
        setShowLinkPopup(true);
    }

    function closeClaimLinkPopup() {
        setShowLinkPopup(false);
        props.onClose();
    }

    return (
        <ModalPage onClose={props.onClose} hideCross={true} className={styles['modalWrapp']} >
            <div className={styles['modalPopup']}>
                <div className={styles['wrapperPopup']}>
                    <div className={styles['crossWrapp']}>
                        <div className={styles['cross']} onClick={props.onClose}>
                            <Icon name='cross' />
                        </div>
                    </div>
                    <form className={styles['form']} onSubmit={onSubmit}>
                        <div className={styles['form__header']}>
                            <span className={styles['title']}>New deposit</span>
                            <span className={styles['description']}>
                                Choose deposit size below,
                                <br />
                                and send the link to whom you love
                            </span>
                        </div>
                        <Input
                            label='Deposit size'
                            type='text'
                            name='deposit'
                            value={credentials.deposit}
                            onChange={handleCredentials}
                            onBlur={handleZeroSum}
                        />
                        <Input
                            label='Your name (optional)'
                            type='text'
                            name='name'
                            onChange={handleNameChange}
                        />
                        <Cover label='Cover' name='cover' onChange={handleCover} />
                        <div className={styles['button']}>
                            {!isAllowanceSufficient() && (
                                <>
                                    <div className={styles['attention']}>
                                        <Attention color='blue'>
                                            Insufficient allowance. Drizzle needs your approval to move funds in order to make a deposit. Please accept in Metamask
                                        </Attention>
                                    </div>
                                    <Button size='l' type='primary' onClick={handleApprove}>
                                        Set allowance
                                    </Button>
                                </>
                            )}
                            <Button
                                size='l'
                                type='primary'
                                onClick={handleDeposit}
                                disabled={!isAllowanceSufficient()}
                            >
                                Make a ${credentials.deposit.toString()} deposit
                            </Button>
                        </div>
                    </form>
                    <div className={styles['content']}>
                        <div className={styles['content__picture']}>
                            <img
                                src={credentials.img}
                                className={styles['img']}
                                alt='drizzle send deposit'
                            />
                            <div className={styles['extra']}>
                                <div className={styles['extra__text']}>Here's how your Drizzle will appear for the receiver</div>
                                <div className={styles['extra__icon']}>
                                    <Icon name='arrow_vector_down' />
                                </div>
                            </div>
                        </div>
                        <div className={styles['info']}>
                            <span className={styles['info__title']}>
                                {name} committed a&nbsp;${credentials.deposit.toString()} deposit to your name
                            </span>
                            <span className={styles['info__desription']}>
                                You will be collecting interest from this deposit
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {modalAddMetamask && <PopupAddExtMetamask onClose={closeAddMetamask} />}
            {showLinkPopup && <PopupDepositPlaced onClose={closeClaimLinkPopup} claimLink={finalizeClaimLink()} />}
        </ModalPage >
    )
}

