import {BackgroundColor} from "shared/db-types";

const COLOR_WHITE  = 'white';
const COLOR_ORANGE = '#F5E7EA';
const COLOR_YELLOW = '#FFF5EC';
const COLOR_BLUE   = '#E8EAF5';
const COLOR_GREEN  = '#DCEDDD';

export const COLORS_DATA = [
    {
        id: '0',
        color: BackgroundColor.White,
        value: COLOR_WHITE,
    },
    {
        id: '1',
        color: BackgroundColor.Yellow,
        value: COLOR_YELLOW
    },
    {
        id: '2',
        color: BackgroundColor.Orange,
        value: COLOR_ORANGE
    },
    {
        id: '3',
        color: BackgroundColor.Blue,
        value: COLOR_BLUE
    },
    {
        id: '4',
        color: BackgroundColor.Green,
        value: COLOR_GREEN
    },
]

export const THEME_COLORS = {
    'white' : COLOR_WHITE,
    'orange': COLOR_ORANGE,
    'yellow': COLOR_YELLOW,
    'blue'  : COLOR_BLUE,
    'green' : COLOR_GREEN
}