import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './NavBar.module.scss'
import { Icon } from 'components/Icon/Icon'
import { MenuBar } from './components/MenuBar/MenuBar'
import { addPreviewsToMenubar, addStatsToMenubar, MENUBAR_LINKS, MENUBAR_PREVIEWS } from 'layout/NavBar/consts'
import { Button } from 'components/Button/Button'
import { UserMenu } from './components/UserMenu/UserMenu'
import { PopupEditPage } from 'components/PopupEditPage/PopupEditPage'
import { useWeb3 } from "../../components/Web3Context/Web3Context";
import { useDBUser } from "../../components/UserInfoContext/UserInfoContext";
import classnames from "classnames";
import { useIsMobile } from 'hooks/useIsMobile'
import { MobileMenu } from './components/MobileMenu/MobileMenu'

const AB_TESTNET_ADDRESS = "0x9F23A8fb2965612AE86EFCD3e4867Bac601d1f44";
const FAQ_URL = "https://bubbly-echium-905.notion.site/Drizzle-FAQ-c5fc9000e3e74670b3510679b209d19a";

type Props = {
    showPreviews?: boolean;
}

const MUNE_DATA = {
    name: 'Leopold',
    mail: 'alex696@madeindalls.com',
    account: '0x8a33...Odeb',
}

export function NavBar(props: Props) {
    const [showPopup, setShowPopup] = React.useState(false)
    const [mobileMenu, setMobileMenu] = React.useState(false)

    const { isMobile } = useIsMobile()

    const navigate = useNavigate()
    const location = useLocation()

    // test data session 
    const session = !props.showPreviews;

    function navigateTo(link) {
        navigate(`/${link}`)
    }

    function openPopupEditPage() {
        setShowPopup(true)
    }

    function closePopupEditPage() {
        setShowPopup(false)
    }

    function navigateToPatrons() {
        window.open(process.env.REACT_APP_BASE_URL + '/patrons', '_blank')
    }

    function navigateToCreators() {
        window.open(process.env.REACT_APP_BASE_URL + '/creators', '_blank')
    }

    function navigateToAbout() {
        window.open(process.env.REACT_APP_BASE_URL + '/about', '_blank')
    }

    function navigateToFaq() {
        window.open(FAQ_URL, '_blank')
    }

    function navigateToYourPage() {
        window.open(process.env.REACT_APP_BASE_URL + '/u/' + user.link, '_blank');
    }

    function navigateToLogin() {
        navigate('/auth/login')
    }

    function navigateToSignup() {
        navigate('/auth/signup')
    }

    function openMobileMenu() {
        setMobileMenu(true)
    }

    function closeMobileMenu() {
        setMobileMenu(false)
    }


    const { isConnected, userAddress } = useWeb3();
    const { isUser, user } = useDBUser();

    const formMenubarLinks = () => {
        let links = props.showPreviews ? MENUBAR_PREVIEWS : MENUBAR_LINKS;
        if (isConnected && !props.showPreviews && (userAddress === AB_TESTNET_ADDRESS)) links = addStatsToMenubar(links);
        return links;
    }

    const navbarClassName = classnames({
        [styles['container']]: true,
        [styles['container_dark']]: props.showPreviews
    });

    return (
        <>
            <div className={navbarClassName}>
                <div className={styles['wrapper']}>
                    <div className={styles['logoWrapp']}>
                        {isMobile && (
                            <div className={styles['logoMenu']} onClick={openMobileMenu}>
                                <Icon name='menu' />
                            </div>
                        )}
                        <div className={styles['logo']} onClick={() => { }}>
                            <Icon name={props.showPreviews ? 'drizzleNegative' : 'drizzle'} />
                        </div>
                    </div>
                    {!isMobile && <MenuBar data={formMenubarLinks()} isNegative={props.showPreviews} />}
                </div>
                <div className={styles['wrapper']}>
                    {!isMobile && (
                        <Button
                            size='s'
                            type='transparent'
                            iconName='external'
                            iconRight={true}
                            stretch={false}
                            onClick={navigateToFaq}
                        >
                            FAQ
                        </Button>
                    )}
                    <div className={styles['buttons']}>
                        {session ? (
                            <>
                                {isUser && (
                                    <Button
                                        size='s'
                                        type='dark'
                                        iconName='view_32'
                                        stretch={false}
                                        onClick={navigateToYourPage}>
                                        Your page
                                    </Button>
                                )}
                                {!isMobile && (
                                    <UserMenu
                                        onLogout={() => { console.log("Trying to logout!") }}
                                        onOpenEditPage={openPopupEditPage} />
                                )}
                            </>
                        ) : (
                            <>
                                <Button
                                    size='s'
                                    type='white'
                                    stretch={false}
                                    onClick={navigateToLogin}>
                                    Log in
                                </Button>
                                <Button
                                    size='s'
                                    type='white'
                                    stretch={false}
                                    onClick={navigateToSignup}>
                                    Get started
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {showPopup && <PopupEditPage onClose={closePopupEditPage} />}
            {mobileMenu && <MobileMenu
                onClose={closeMobileMenu}
                onOpenEditPage={openPopupEditPage}
                menu={MENUBAR_LINKS}
                userData={MUNE_DATA} />}
        </>
    );
}

