import styles from './Auth.module.scss'
import React from 'react';
import { AuthForm } from './AuthForm/AuthForm'
import { AuthNavBar } from './AuthNavbar/AuthNavBar'
import mobileFrame from 'assets/mobile_frame.png'
import login from 'assets/login.png'
import claimPreview from 'assets/claimPreview.png'
import classnames from 'classnames'
import { useIsMobile } from 'hooks/useIsMobile'
import { useLocation } from 'react-router-dom'
import drizzle from 'assets/drizzle.svg'
import { Icon } from 'components/Icon/Icon';

const PAGE_TITLE = "Getting you in | Drizzle. Sponsor without spending";

type Props = {
    authType: 'login' | 'signup';
}

type DisplayPicture = 'phone' | 'claimCard' | 'default';

export function Auth(props: Props) {
    const [picture, setPicture] = React.useState<DisplayPicture>('default')
    const [showNavBar, setShowNavBar] = React.useState(true);
    const [currentStep, setCurrentStep] = React.useState(1);
    const { isMobile, screenWidth } = useIsMobile()


    const location = useLocation()
    const pathname = String(location.pathname).split('/')[1]
    const isLogin = pathname !== 'login'

    const hideNavBar = () => {
        setShowNavBar(false);
    }

    const returnNavBar = () => {
        setShowNavBar(true);
    }

    const showClaimCard = () => {
        setPicture('claimCard');
    }

    const showDefault = () => {
        setPicture('default');
    }

    const showPhone = () => {
        setPicture('phone');
    }

    function handleBack() {
        // dispatch(previousStep())
    }

    function handleChange(step: string) {
        console.log('step', step)

        let newStep

        if (step === '1') {
            newStep = 1
        }
        if (step === '2') {
            newStep = 2
        }
        if (step === '3c' || step === '3p') {
            newStep = 3
        }
        if (step === 'editPage') {
            newStep = 4
        }

        setCurrentStep(newStep)
    }


    const logoClassName = classnames({
        [styles['logo']]: true,
        [styles['logo_pink']]: picture !== 'default'
    })

    React.useEffect(() => {
        document.title = PAGE_TITLE;
    }, []);

    if (screenWidth < 1300) {
        const showMobileContent = currentStep >= 4 && isLogin
        const showNavBar = currentStep === 1 && currentStep < 4
        const showBack = currentStep > 1 && currentStep < 4 && isLogin

        return (
            <div className={styles['mobile']}>
                {showNavBar && (
                    <AuthNavBar authType={props.authType} />
                )}
                {/* {showBack && (
                    <div
                        className={styles['back']}
                        onClick={handleBack}>
                        <Icon name='back' />
                    </div>
                )} */}
                {!showMobileContent && (
                    <div className={styles['mobile__logo']}>
                        <img
                            src={drizzle}
                            className={styles['mobile__img']}
                            alt='drizzle login'
                        />
                    </div>
                )}
                <div className={styles['mobile__content']}>
                    <AuthForm
                        authType={props.authType}
                        showClaimCard={showClaimCard}
                        showDefault={showDefault}
                        showPhone={showPhone}
                        hideNavBar={hideNavBar}
                        returnNavBar={returnNavBar}
                        onChangeStep={handleChange}
                    />
                </div>
            </div >
        )
    }

    return (
        <div className={styles['wrapper']}>
            {showNavBar && <AuthNavBar authType={props.authType} />}
            <div className={styles['content']}>
                <AuthForm
                    authType={props.authType}
                    showClaimCard={showClaimCard}
                    showDefault={showDefault}
                    showPhone={showPhone}
                    hideNavBar={hideNavBar}
                    returnNavBar={returnNavBar}
                />
            </div>
            <div className={logoClassName}>
                {(picture === 'default') &&
                    <img
                        src={login}
                        className={styles['img']}
                        alt='drizzle login'
                    />
                }
                {(picture === 'phone') &&
                    <div className={styles['mobile']}>
                        <img
                            src={mobileFrame}
                            alt='drizzle mobile'
                        />
                    </div>
                }
                {(picture === 'claimCard') &&
                    <div className={styles['mobile']}>
                        <img
                            src={claimPreview}
                            alt='drizzle claim'
                        />
                    </div>
                }
            </div>
        </div>
    )
}

