import React from 'react'
import styles from './PopupEditTarget.module.scss'
import { Modal } from 'components/Modal/Modal'
import { Icon } from 'components/Icon/Icon'
import { Input } from 'components/Input/Input'
import { Button } from 'components/Button/Button'

type Props = {
    onClose: () => void;
}

export function PopupEditTarget(props: Props) {
    const [credentials, setCredentials] = React.useState({
        target: null,
        mail: null
    })

    function handleCredentials(target) {
        setCredentials({
            ...credentials,
            [target.name]: target.value,
        });
    };


    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
    }

    return (
        <Modal onClose={props.onClose}>
            <form className={styles['wrapper']} onSubmit={onSubmit}>
                <div className={styles['saving']}>
                    <Icon name='saving_389' />
                </div>
                <div className={styles['title']}>
                    Set target value and we'll notify you once your earnings meet it
                </div>
                <Input
                    label='Target value'
                    type='text'
                    name='target'
                    onChange={handleCredentials}
                />
                <Input
                    label='Your email'
                    type='text'
                    name='mail'
                    onChange={handleCredentials}
                />
                <div className={styles['button']}>
                    <Button
                        size='l'
                        type='border'
                        stretch={false}
                        onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button size='l' type='dark'>
                        Set target value
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

