import {ReactNode, useEffect, useState} from 'react'
import classnames from 'classnames'
import styles from './Button.module.scss'
import { Icon } from 'components/Icon/Icon'

export type ButtonStyle = 'dark' | 'light' | 'primary' | 'border' | 'transparent' | 'white';
export type ButtonSize = 'l' | 'm' | 's' | 'xs' | 'xs_tall';

type Props = {
    children: ReactNode;
    size: ButtonSize;
    type: ButtonStyle;
    onClick?: () => void;
    behaviourType?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    iconName?: string;
    stretch?: boolean;
    iconRight?: boolean;
    isPreview?: boolean;
};

const BUTTON_ANIMATION_DELAY_MS = 1200;

export function Button(props: Props): JSX.Element {
    const [isClicked, setIsClicked] = useState(false);

    const clickedVersions = {
        'dark': 'white',
        'light': 'white',
        'primary': 'white',
        'border': 'white',
        'transparent': 'white',
        'white': 'border'
    }

    const buttonClassNames = classnames({
        [styles['button']]: true,
        [styles[`button_${isClicked ? clickedVersions[props.type] : props.type}`]]: true,
        [styles[`button_${props.size}`]]: true,
        [styles['button_stretch']]: props.stretch,
        [styles['button_icon-right']]: props.iconRight,
    })

    const previewOnClick = () => {
        setIsClicked(true);
    }

    useEffect(() => {
        if (isClicked) {
            const timer = setTimeout(() => {
                setIsClicked(false);
            }, BUTTON_ANIMATION_DELAY_MS);
            return () => clearTimeout(timer);
        }
    }, [isClicked]);

    return (
        <button
            type={props.behaviourType}
            className={buttonClassNames}
            onClick={props.isPreview ? previewOnClick : props.onClick}
            disabled={props.disabled}
        >
            {props.iconName && (
                <div className={styles['icon']}>
                    <Icon name={props.iconName} />
                </div>
            )}
            <div className={styles['text']}>{isClicked ? "Sorry! Preview!" : props.children}</div>
        </button>
    );
}

Button.defaultProps = {
    behaviourType: 'button',
    type: 'dark',
    stretch: true,
    iconRight: false
};
