import React from 'react'
import styles from './WelcomeBlock.module.scss'
import saving from 'assets/saving.png'

type Props = {
    children?: React.ReactNode,
    text?: string
}

export function WelcomeBlock(props: Props) {
    return (
        <div className={styles['wrapper']}>
            <div className={styles['text']}>
                <span className={styles['title']}>
                    Hey, Welcome to Drizzle!
                </span>
                <br />
                {props.text}
            </div>
            <img src={saving} alt='money' className={styles['img']} />
        </div>
    );
}

