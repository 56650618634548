export function formatWalletAddress(walletAddress, prefixLength = 6, suffixLength = 4) {
    if (walletAddress === null || walletAddress === undefined) {
        return 'нет данных'
    }
    // Проверяем, достаточная ли длина адреса для форматирования
    if (walletAddress.length > prefixLength + suffixLength) {
        // Возвращаем отформатированную строку
        return walletAddress.slice(0, prefixLength) + '...' + walletAddress.slice(-suffixLength);
    }
    // Если адрес слишком короткий, просто возвращаем его как есть
    return walletAddress;
}