import React, { useEffect, useState } from 'react';
import { truncateEthAddress } from "../helpers";
import { Icon } from "../Icon/Icon";
import styles from './EthAddress.module.scss';
import { useDBUser } from "../UserInfoContext/UserInfoContext";
import { ethers } from "ethers";
import { useIsMobile } from 'hooks/useIsMobile';

interface Props {
    address: ethers.AddressLike;
    isClaimLink?: boolean;
}

export function EthAddress(props: Props) {
    const [isCopied, setIsCopied] = useState(false);
    const [displayString, setDisplayString] = useState(truncateEthAddress(props.address));
    const [pageLink, setPageLink] = useState(null);
    const { lookupLinkByAddress, lookupUsernameByAddress } = useDBUser();
    const { isMobile } = useIsMobile()

    const copyAddress = async () => {
        await navigator.clipboard.writeText(String(props.address));
        setIsCopied(true);
    }

    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => {
                setIsCopied(false);
            }, 800);
            return () => clearTimeout(timer);
        }
    }, [isCopied]);

    useEffect(() => {
        (async () => {
            const maybeUsername = await lookupUsernameByAddress(props.address);
            if (props.isClaimLink) {
                setDisplayString("Claim link (temp address " + displayString + ")");
            } else if (maybeUsername) {
                setDisplayString(maybeUsername + " (" + displayString + ")");
            } else {
                setDisplayString(displayString);
            }
            const maybeLink = await lookupLinkByAddress(props.address);
            if (maybeLink) setPageLink(process.env.REACT_APP_BASE_URL + '/u/' + maybeLink);
        })();
    }, []);

    const maxLength = 8
    const name = displayString.length > maxLength ? displayString.slice(0, maxLength) + '...' : displayString

    return (
        <>
            <span className={styles.ethAddressWrapper} onClick={copyAddress}>
                <div className={styles.tooltip}>
                    {name}
                    <span className={styles.tooltiptext}>{isCopied ? "Copied!" : String(props.address)}</span>
                    &nbsp;
                    {<Icon name={isCopied ? "checkmark" : "copy"} />}
                </div>
            </span>
            {/* {!!pageLink &&
                <>
                    &nbsp;&nbsp;
                    <a
                        className={styles.page_link}
                        href={pageLink}
                        target="_blank">
                        (page ↗)
                    </a>
                </>
            } */}
        </>
    )
}