import React from 'react'
import styles from './PopupEditChips.module.scss'
import { Modal } from 'components/Modal/Modal'
import { Button } from 'components/Button/Button'
import { Input } from 'components/Input/Input'

type Props = {
    onClose: () => void,
    onSave: (newValue: string) => void,
    title: string,
    name: string,
    value: string,
}

export function PopupEditChips(props: Props) {
    const [value, setValue] = React.useState(props.value);

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
    }

    function handleChange(target) {
        setValue(target.value);
    }

    function handleSubmit() {
        props.onSave(value);
        props.onClose();
    }

    const handleBlur = () => {
        const trimmedValue = extractNickname(value);
        setValue(trimmedValue);
    };

    const extractNickname = (value) => {
        if (value.includes('/')) {
            const parts = value.split('/');
            if (value[value.length - 1] === '/') {
                return parts[parts.length - 2];
            } else {
                return parts[parts.length - 1];
            }
        }
        return value;
    };

    const handlePaste = (event) => {
        event.preventDefault();

        const pastedText = event.clipboardData.getData('text');

        const extractedNickname = extractNickname(pastedText);
        setValue(extractedNickname);
    };

    return (
        <Modal onClose={props.onClose}>
            <form className={styles['wrapper']} onSubmit={onSubmit}>
                <div className={styles['content']}>
                    <span className={styles['title']}>Edit link</span>
                    <Input
                        label={props.title}
                        type='text'
                        name={props.name}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onPaste={handlePaste}
                    />
                </div>
                <div className={styles['button']}>
                    <Button size='m' type='border' onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button size='m' type='dark' onClick={handleSubmit}>
                        Save
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

