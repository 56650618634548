import React from 'react';
import { ModalPage } from 'components/ModalPage/ModalPage'
import { EditPage } from 'components/PopupEditPage/EditPage/EditPage'

const PAGE_TITLE = "Edit your profile | Drizzle. Sponsor without spending";

type Props = {
    onClose: () => void,
}

export function PopupEditPage(props: Props) {
    React.useEffect(() => {
        document.title = PAGE_TITLE;
    }, []);

    return (
        <ModalPage hideCross>
            <EditPage onClose={props.onClose} />
        </ModalPage>
    )
}

