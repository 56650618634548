export const MENUBAR_LINKS = [
    {
        id: 0,
        title: 'Requesting',
        value: 'REQUEST',
        link: '/request',
    },
    {
        id: 1,
        title: 'Supporting',
        value: 'SUPPORT',
        link: '/support'
    },
]

const MENUBAR_STATS = [
    {
        id: 2,
        title: 'Drizzle Stats',
        value: 'DRIZZLE STATS',
        link: '/dashboard'
    },
]

export const MENUBAR_PREVIEWS = [
    {
        id: 3,
        title: 'Requesting (Preview)',
        value: 'REQUEST',
        link: '/preview/request',
    },
    {
        id: 4,
        title: 'Supporting (Preview)',
        value: 'SUPPORT',
        link: '/preview/support'
    },
    {
        id: 5,
        title: 'Claim (Preview)',
        value: 'SUPPORT',
        link: '/preview/claim'
    },
];

export const addStatsToMenubar = (links) => (links.concat(MENUBAR_STATS));

export const  addPreviewsToMenubar = (links) => (links.concat(MENUBAR_PREVIEWS));