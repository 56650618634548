import React from 'react'
import { Icon } from 'components/Icon/Icon'
import styles from './Input.module.scss'
import classnames from 'classnames'

type Props = {
    label?: string,
    type: 'text' | 'number',
    size?: 'm' | 'l',
    defaultValue?: string,
    placeholder?: string,
    onChange?: (target: EventTarget & HTMLInputElement) => void,
    name: string,
    maxWidth?: string,
    error?: string,
    value?: string | number,
    isChecked?: boolean,
    min?: number,
    onBlur?: () => void,
    onPaste?: (event: any) => void,
}

export function Input(props: Props): JSX.Element {

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (props.onChange)
            props.onChange(event.currentTarget)
    }

    const checkedIcon = props.isChecked ? 'check' : 'error'

    const classNames = classnames({
        [styles['input']]: true,
        [styles[`input_${props.size}`]]: true,
    })


    return (
        <div className={styles['wrapper']} style={{ maxWidth: `${props.maxWidth}px` }}>
            <label htmlFor={props.name} className={styles['label']}>
                {props.label}
            </label>
            <div className={styles['container']}>
                <input
                    id={props.name}
                    name={props.name}
                    className={classNames}
                    placeholder={props.placeholder}
                    type={props.type}
                    onChange={handleChange}
                    value={props.value}
                    min={props.min}
                    onBlur={props.onBlur}
                    onPaste={props.onPaste}
                />
                {props.isChecked !== undefined && (
                    <div className={styles['icon']}>
                        <Icon name={checkedIcon} />
                    </div>
                )}
            </div>
            {props.error && <div className={styles['error']}>{props.error}</div>}
        </div>
    )
}

Input.defaultProps = {
    size: 'm'
};

