import React from 'react'
import styles from './SignupStepPublicEdit.module.scss'
import { Button } from 'components/Button/Button'
import { FormEdit } from 'components/PopupEditPage/EditPage/FormEdit/FormEdit'
import {EmptyUser} from "shared/db-types";
import {useWeb3} from "../../../../../components/Web3Context/Web3Context";
import {useDBUser} from "../../../../../components/UserInfoContext/UserInfoContext";

type Props = {
    onSubmit?: () => void,
}

export function SignupStepPublicEdit(props: Props) {
    const [editedUser, setEditedUser] = React.useState(EmptyUser);

    const { userAddress } = useWeb3();
    const { user, saveUserToDB } = useDBUser();

    async function handleSubmit() {
        const userUpd = {
            ...editedUser,
            link: user.link || userAddress.toLowerCase()
        }
        saveUserToDB(userUpd);
        props.onSubmit()
    }

    React.useEffect(() => {
        setEditedUser(user);
    }, []);

    return (
        <div className={styles['wrapper']}>
            <FormEdit
                title='Set up your page'
                user={editedUser}
                onChange={setEditedUser}
            />
            <div className={styles['button']}>
                <Button
                    size='l'
                    type='dark'
                    iconName='forward'
                    iconRight
                    onClick={handleSubmit}
                >
                    Create page and get going!
                </Button>
            </div>
        </div >
    );
}

