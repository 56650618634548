import {ethers, EventLog, Log, Eip1193Provider, BrowserProvider} from "ethers";
import {USDC_DECIMALS} from "../consts/contracts";

const ENCRYPTION_KEY = "drizzleFinance2024";
export const CHAIN_ID_LOCAL = '0x7a69';
export const CHAIN_ID_ETH_TESTNET = '0xaa36a7';
export const CHAIN_ID_ETH_MAINNET = '0x1';

declare global {
    interface Window {
        ethereum?: ethers.Eip1193Provider;
    }
}

export async function switchNetwork() {
    if (typeof window.ethereum !== 'undefined') {
        try {
            const ethereum = window.ethereum as Eip1193Provider;
            const provider = new BrowserProvider(ethereum);

            const network = await provider.getNetwork();
            const chain_id_current = network.chainId;
            const chain_id_target = process.env.REACT_APP_DEPLOYED_LOCAL == "TRUE" ? BigInt(CHAIN_ID_LOCAL) : BigInt(CHAIN_ID_ETH_TESTNET);

            console.log("ChainID current / target", chain_id_current, chain_id_target);

            if (chain_id_current !== chain_id_target) {
                await provider.send('wallet_switchEthereumChain', [{ chainId: `0x${chain_id_target.toString(16)}` }]);
            }
        } catch (e) {
            console.log("Trying to switch networks", e);
        }
    } else {
        console.log("Ethereum object not found");
    }
}

export function formatNumberWithCommas(sign: string, number: string) {
    const numStr = Number(ethers.formatUnits(number, USDC_DECIMALS)).toFixed(2).toString();
    return `${sign}${numStr}`
}

export function formatNumberWithCommasAndDecimal(number, prefix = '') {
    // Преобразуем число в фиксированный десятичный формат с двумя знаками после запятой
    const fixedNumber = number.toFixed(2)

    // Разделяем результат на целую и десятичную часть
    let [integerPart, decimalPart] = fixedNumber.split('.')

    // Применяем форматирование с разделением тысяч к целой части
    integerPart = parseInt(integerPart).toLocaleString('en-US')

    // Возвращаем результат с добавлением десятичной части
    return `${prefix} ${integerPart}.${decimalPart}`

}

export function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                };
            } else {
                return {
                    address: "",
                };
            }
        } catch (err) {
            return {
                address: "",
            };
        }
    } else {
        return {
            address: "",
        }
    }
}

export async function signClaimMessage(
    signer: ethers.Signer,
    newBeneficiary: string,
    token: string,
    yieldProtocol: string
    ): Promise<{ v: number; r: string; s: string; signature: string }> {
    // bytes32 prefixedHashAddress = keccak256(abi.encodePacked(prefix, _newBeneficiary, _token, _yieldProtocol));
    // Ensure addresses are checksummed
    const newBen = ethers.getAddress(newBeneficiary);
    const tok = ethers.getAddress(token);

    // Create the message hash
    const messageHash = ethers.solidityPackedKeccak256(
        ['address', 'address', 'string'],
        [newBen, tok, yieldProtocol]
    );

    // Sign the hash
    const signature = await signer.signMessage(ethers.getBytes(messageHash));

    // Split the signature
    const sig = ethers.Signature.from(signature);
    console.log("Signer addr:", await signer.getAddress());

    return {
        v: sig.v,
        r: sig.r,
        s: sig.s,
        signature: signature
    };
}

async function getKey(secret: string): Promise<CryptoKey> {
    const encoder = new TextEncoder();
    const keyMaterial = await window.crypto.subtle.importKey(
        'raw',
        encoder.encode(secret),
        { name: 'PBKDF2' },
        false,
        ['deriveKey']
    );

    return window.crypto.subtle.deriveKey(
        {
            name: 'PBKDF2',
            salt: encoder.encode('salt'),
            iterations: 100000,
            hash: 'SHA-256'
        },
        keyMaterial,
        { name: 'AES-GCM', length: 256 },
        false,
        ['encrypt', 'decrypt']
    );
}

export const decryptPkey = async (ciphertext: string): Promise<ethers.Wallet> => {
    const key = await getKey(ENCRYPTION_KEY);
    const decoder = new TextDecoder();
    const encoder = new TextEncoder();

    const encryptedData = new Uint8Array(atob(ciphertext).split('').map(char => char.charCodeAt(0)));
    const iv = encryptedData.slice(0, 12);
    const data = encryptedData.slice(12);

    const decryptedData = await window.crypto.subtle.decrypt(
        { name: 'AES-GCM', iv: iv },
        key,
        data
    );

    const plaintext = decoder.decode(decryptedData);
    return new ethers.Wallet(plaintext);
}

export const createPkey = async (): Promise<{ address: string, encryptedPkey: string }> => {
    const wallet = ethers.Wallet.createRandom();
    const address = wallet.address;
    const pkey = wallet.privateKey;

    const key = await getKey(ENCRYPTION_KEY);
    const encoder = new TextEncoder();
    const iv = window.crypto.getRandomValues(new Uint8Array(12));

    const encryptedData = await window.crypto.subtle.encrypt(
        { name: 'AES-GCM', iv: iv },
        key,
        encoder.encode(pkey)
    );

    const encryptedArray = new Uint8Array(iv.byteLength + encryptedData.byteLength);
    encryptedArray.set(iv, 0);
    encryptedArray.set(new Uint8Array(encryptedData), iv.byteLength);

    const encryptedPkey = btoa(String.fromCharCode.apply(null, encryptedArray));
    return { address, encryptedPkey };
}

export const createClaimPayload = (
            encryptedPkey: string,
            senderAddr: string,
            senderName?: string,
            amount?: string,
            color?: string,
        ): string => {
    const base64EncryptedPkey = btoa(encryptedPkey);
    const keyParam = encodeURIComponent(base64EncryptedPkey);
    const senderAddrParam = `/${encodeURIComponent(btoa(senderAddr))}`;
    const senderNameParam = senderName ? `/${encodeURIComponent(btoa(senderName))}` : '';
    const amountParam = amount     ? `/${encodeURIComponent(btoa(amount))}`     : '';
    const colorParam = color ? `/${encodeURIComponent(btoa(color))}` : '';
    return `${keyParam}${senderAddrParam}${senderNameParam}${amountParam}${colorParam}`;
}

export const createClaimUrl = (
            encryptedPkey: string,
            senderAddr: string,
            senderName?: string,
            amount?: string,
            color?: string,
        ): string => {
    const baseUrl = process.env.REACT_APP_BASE_URL || '(set url in dotenv)';
    const claimPayload = createClaimPayload(encryptedPkey, senderAddr, senderName, amount, color);
    return `${baseUrl}/claim/${claimPayload}`
}

export const truncateEthAddress = (address) => {
    var truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
    var match = address.match(truncateRegex);
    if (!match)
        return address;
    return match[1] + "\u2026" + match[2];
}

export const formDrizzlesMap = async (contract, from, to) => {
    interface DashboardItem {
        patron: string,
        beneficiary: string,
        currency: string,
        deposit: string,
        unclaimed: string,
    }

    function eventsToArray(events: (EventLog | Log)[]): DashboardItem[] {
        let entries;
        (async () => {
            const APR_PER_DAY_TESTNET = "18.86% per day (testnet)";
            const USDC_DECIMALS = 18;

             entries = events.flatMap((e) => {
                const e1 = e as EventLog;
                if (e1.args && e1.args.length > 3) {
                    return ({
                        patron: e1.args[0],
                        beneficiary: e1.args[1],
                        currency: 'USDC',
                        deposit: ethers.formatUnits(e1.args[4], USDC_DECIMALS),
                    })
                } else {
                    return [];
                }
            });

            entries = await Promise.all(entries.map(async (e) => {
                return e;
                //e.unclaimed = ethers.formatUnits(await contract.viewInterest(e.patron, e.beneficiary), USDC_DECIMALS);
            }));
        })();
        return entries;
    }

    const depositFilter = contract.filters.PrincipalAdded(from, to, null, null, null);
    const withdrawFilter = contract.filters.PrincipalWithdrawn(from, to, null, null, null);
    const depositEvents = await contract.queryFilter(depositFilter);
    const depositEventsArr: DashboardItem[] = eventsToArray(depositEvents);
    const withdrawEvents = await contract.queryFilter(withdrawFilter);
    const withdrawEventsArr: DashboardItem[] = eventsToArray(withdrawEvents);
    const drizzles = new Map();

    depositEventsArr.forEach((event) => {
        const {patron, beneficiary, currency, deposit} = event;

        if (drizzles.has(patron + beneficiary)) {
            const b = drizzles.get(patron + beneficiary);
            b.deposit = (Number(b.deposit) + Number(deposit)).toString();
        } else {
            drizzles.set(patron + beneficiary, {patron, beneficiary, deposit, currency});
        }
    });

    withdrawEventsArr.forEach((event) => {
        const {patron, beneficiary, currency, deposit} = event;

        if (drizzles.has(patron + beneficiary)) {
            const b = drizzles.get(patron + beneficiary);
            b.deposit = (Number(b.deposit) - Number(deposit)).toString();
        }
    });

    return drizzles;
}