import React from 'react';
import styles from './Deposit.module.scss'
import { Withdraw } from './Withdraw/Withdraw'
import { Icon } from 'components/Icon/Icon'
import { PopupSendDeposit } from "../../../../components/PopupSendDeposit/PopupSendDeposit";

type Props = {
    onDeposit: () => void,
    isPreview?: boolean,
}

export function Deposit(props: Props) {
    const [showPopup, setShowPopup] = React.useState(false)
    const [valueDeposit, setValueDeposit] = React.useState(null)

    function closePopup() {
        setShowPopup(false)
        props.onDeposit();
    }

    function handleSubmit(value) {
        setShowPopup(true)
        setValueDeposit(value)
    }

    const onDeposit = () => {
        setValueDeposit(0);
        props.onDeposit();
    }

    return (
        <>
            <div className={styles['wrapper']}>
                <div>
                    <div className={styles['icon']}>
                        <Icon name='coin_big' />
                    </div>
                    <div className={styles['description']}>
                        Choose deposit size
                        <br />
                        and send the link to whom
                        <br /> you love
                    </div>
                </div>
                <Withdraw onSubmit={handleSubmit} deposit={valueDeposit} setDeposit={setValueDeposit} isPreview={props.isPreview} />
            </div>
            {showPopup && <PopupSendDeposit deposit={valueDeposit} onClose={closePopup} onDeposit={onDeposit} />}
        </>
    );
}

