import styles from './FullDashboard.module.scss'
import { Wrapper } from 'components/Wrapper/Wrapper'
import { Dashboard } from 'components/Dashboard/Dashboard'
import { DRIZZLES_MOCK_DATA } from './consts'
import React from "react";
import { onlyUnique } from "../../components/helpers";
import { toBigInt } from "ethers";
import { useWeb3 } from "../../components/Web3Context/Web3Context";

const PAGE_TITLE = "Hidden Dashboard | Drizzle. Sponsor without spending";

export function FullDashboard() {
    const [tableData, setTableData] = React.useState(DRIZZLES_MOCK_DATA);
    const [tvl, setTvl] = React.useState(39);
    const [totalDrizzles, setTotalDrizzles] = React.useState(40);
    const [uniqueBeneficiaries, setUniqueBeneficiaries] = React.useState([]);
    const [uniquePatrons, setUniquePatrons] = React.useState([]);

    const { isConnected, connectWeb3, loadDrizzlesListFromTo } = useWeb3();

    const populateDashboard = async () => {
        try {
            const drizzles = await loadDrizzlesListFromTo(null, null);
            let dashboardList = [];

            drizzles.forEach((val, ) => {
                dashboardList.push({
                    patron:      val.patron,
                    beneficiary: val.beneficiary,
                    currency:    val.currency,
                    deposit:     val.deposit,
                });
            });

            dashboardList = dashboardList.filter((ben) => ben.deposit > 0);

            setTableData({
                    ...tableData,
                    patrons: dashboardList,
                }
            );

            const uniqueBen = dashboardList.map((e) => e.beneficiary).filter(onlyUnique);
            setUniqueBeneficiaries(uniqueBen);

            const uniquePat = dashboardList.map((e) => e.patron).filter(onlyUnique);
            setUniquePatrons(uniquePat);

            setTotalDrizzles(dashboardList.length);

            const totalDeposits =
                dashboardList
                    .map((i) => i.deposit)
                    .reduce((acc, x) => Number(acc) + Number(x), Number(0))
                    .toString();

            setTvl(totalDeposits);
        } catch (e) {
            console.log("Failing to populate the dashboard", e);
        }
    }

    React.useEffect(() => {
        document.title = PAGE_TITLE;
        (async () => {
            if (!isConnected) {
                connectWeb3();
            } else {
                await populateDashboard();
            }
        })();
    }, [isConnected]);

    const formAnalytics = () => {
        return [
            {
                id: 'tvl',
                name: 'Total value',
                number: tvl.toString(),
                description: 'Total value deposited',
                helpText: 'Combined amount of money committed to Drizzle. Beneficiaries receive interest from that amount',
            },
            {
                id: 'deposits',
                name: 'Total deposits',
                number: String(totalDrizzles),
                description: 'Total deposits',
                helpText: 'The amount of drizzles sent',
                isCurrency: false,
            },
            {
                id: 'patrons',
                name: 'Total patrons',
                number: String(uniquePatrons.length),
                description: 'People sending drizzles',
                helpText: 'This indicator reflects how generous Drizzle patrons are',
                isCurrency: false,
            },
            {
                id: 'beneficiaries',
                name: 'Total beneficiaries',
                number: String(uniqueBeneficiaries.length),
                description: 'People getting drizzles',
                helpText: 'This indicator reflects how prosperous Drizzle beneficiaries are',
                isCurrency: false,
            },
            {
                id: 'interest',
                name: 'Current interest rate',
                number: "18.86%",
                description: 'Daily interest (testnet)',
                helpText: 'One can hope',
                isCurrency: false,
            },
        ]
    }

    return (
        <Wrapper>
            <div className={styles['container']}>
                <Dashboard title={"Drizzles"} data={tableData} analytics={formAnalytics()} balance={toBigInt(0)} />
            </div>
        </Wrapper>
    );
}

