import { TypeHeader } from 'components/Dashboard/consts'
import styles from './RowHeader.module.scss'
import { useIsMobile } from 'hooks/useIsMobile'

type Props = {
    headers: TypeHeader[]
}

export function RowHeader(props: Props) {
    const { screenWidth } = useIsMobile()

    console.log('header ', props.headers)

    return (
        <div className={styles['wrapper']}>
            {props.headers.map((header) => {
                if (screenWidth < 1050 && !header.cell.isMobile) {
                    return null
                }

                if (screenWidth > 1050 && header.id === 'mobile') {
                    return null
                }

                return (
                    <div
                        className={styles['item']}
                        key={header.id}
                        style={{ width: header.cell.width }}>
                        {header.name}
                    </div>
                )
            })}
        </div>
    )
}

