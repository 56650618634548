import React from 'react';
import styles from './Claim.module.scss'
import { Icon } from 'components/Icon/Icon'
import { Button } from 'components/Button/Button'
import blue from 'assets/cover_blue.png';
import green from 'assets/cover_green.png';
import gray from 'assets/cover_gray.png';
import yellow from 'assets/cover_yellow.png';
import {decryptPkey} from "../../components/helpers";
import {AlreadyClaimed} from "./AlreadyClaimed/AlreadyClaimed";
import {Link} from "react-router-dom";
import {useWeb3} from "../../components/Web3Context/Web3Context";
import {PreviewSign} from "../../components/PreviewSign/PreviewSign";

const DO_WE_USE_GOOGLE = false;
const PAGE_TITLE = "Claim your drizzle! | Drizzle. Sponsor without spending";

interface Props {
    encryptedPkey?: string;
    senderAddr?: string;
    senderName?: string;
    amount?: number;
    color?: string;
    isPreview? : boolean;
}

export function Claim(props: Props) {
    const [wasClaimed, setWasClaimed] = React.useState(false);
    const [justClaimed, setJustClaimed] = React.useState(false);

    const { connectWeb3, isConnected, viewBeneficiaryTvl, claim } = useWeb3();

    function decodeColor() {
        const colorMap = {
            yellow,
            gray,
            green,
            blue
        };

        return colorMap[props.color] || blue;
    }

    function handleClaimDrizzle() {
        (async () => {
            const claimWalletSigner = await decryptPkey(props.encryptedPkey);
            await claim(props.senderAddr, claimWalletSigner);
            setJustClaimed(true);
        })().catch(console.error);
    }

    React.useEffect(() => {
        document.title = PAGE_TITLE;
        (async () => {
            if (props.encryptedPkey) {
                const claimWallet = await decryptPkey(props.encryptedPkey);
                if (isConnected) {
                    const tvl = await viewBeneficiaryTvl(claimWallet.address);
                    setWasClaimed(tvl === BigInt(0));
                }
            }
        })();
    }, [isConnected]);

    return (
        <div className={styles['wrapper']}>
            {props.isPreview && <PreviewSign isOnFullPage={true} />}
            <div className={styles['content']}>
                {!wasClaimed ? (
                    <div className={styles['form']}>
                        <h1 className={styles['title']}>
                            {props.senderName || 'An anonymous patron'}&nbsp;
                            committed { props.isPreview ? 'a $800' : (props.amount ? 'a $' + props.amount : 'an unknown sized')} deposit to your name
                        </h1>
                        <div>
                            <article className={styles['item']}>
                                <Icon name='procent' />
                                <span>
                                    You will be collecting interest from this
                                    <br />
                                    deposit
                                </span>
                            </article>
                            <article className={styles['item']}>
                                <Icon name='safe' />
                                <span>
                                    Deposit is the amount that {props.senderName || 'the anonymous patron'}
                                    <br />
                                    locked for growth.
                                </span>
                            </article>
                            <article className={styles['item']}>
                                <Icon name='coin_gold' />
                                <span>
                                    As the interest accrues towards&nbsp;
                                    {props.senderName || 'the anonymous patron'}'s deposit, it will be yours to claim
                                </span>
                            </article>
                        </div>
                        {!isConnected &&
                        <div className={styles['buttons']}>
                            <span className={styles['button-title']}>
                                Authorize to claim interest
                            </span>
                            {DO_WE_USE_GOOGLE &&
                                <Button
                                    size='m'
                                    type='dark'
                                    iconName='google'
                                    onClick={() => {
                                    }}
                                >
                                    Continue with Google
                                </Button>
                            }
                            <Button
                                size='m'
                                type='dark'
                                iconName='metamask'
                                onClick={connectWeb3}
                                isPreview={props.isPreview}
                            >
                                Continue with Metamask
                            </Button>
                        </div>
                        }
                        {isConnected && !justClaimed &&
                        <Button
                            size='l'
                            type='primary'
                            onClick={handleClaimDrizzle}
                            isPreview={props.isPreview}
                        >
                            Claim your drizzle!
                        </Button>
                        }
                        {isConnected && justClaimed &&
                            <Link to={"/request"}>
                                <Button
                                    size='l'
                                    type='primary'
                                    onClick={() => { }}
                                >
                                    Drizzle claimed. Go to Dashboard!
                                </Button>
                            </Link>
                        }
                    </div>

                ) : <AlreadyClaimed />
                }
            </div>
            <div className={styles['logo_' + String(props.color ?? 'blue')]}>
                <img
                    src={decodeColor()}
                    alt='drizzle pink'
                />
            </div>
        </div>
    )
}

